import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DrawSeatMapsService } from '../draw-seat-maps.service';

import { SeatMapService } from '../seat-map.service';
import * as d3 from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import * as d3org from 'd3';
import * as d3Transition from 'd3-transition';
import { BehaviorSubject } from 'rxjs';


import { Platform } from '@angular/cdk/platform';
import { SyosVenueDetails } from '../../../models/syos-venue-details';
import { SyosSeat } from '../../../models/syos-seat';
import { SeatsSelected } from '../../../models/seats-selected';
import { SyosSection } from '../../../models/syos-section';
import { Cart } from '../../../models/cart';
import { ZoomInterface, ZoomService } from '../../control-zoom/zoom.service';
import { ItemHour } from '../../../models/item-hour';
import { select, selectAll } from 'd3-selection';


@Component({
    selector: 'lib-draw-seatsmap-sale',
    templateUrl: './draw-seatsmap-sale.component.html',
    styleUrls: ['./draw-seatsmap-sale.component.css']
})
export class DrawSeatsmapSaleComponent implements OnInit, OnDestroy {


    constructor(
        public seatMapService: SeatMapService,
        public _platform: Platform, public drawSeatMapsService: DrawSeatMapsService) {
        DrawSeatsmapSaleComponent._platform = _platform;
        this.isMobil = _platform.ANDROID || _platform.IOS;
        //this.isMobil = true;
        // DrawSeatsmapSaleComponent.zoomActive =  !(_platform.ANDROID || _platform.IOS);
    }

    static zoomActive = true;
    static _platform: any;
    static _smallSeatMap = false;


    static watchStartSeatsManager = new BehaviorSubject(null);
    onSeatsSelected = false;
    @Input() venue: SyosVenueDetails;
    @Input() eventId: number;
    @Input() isSubscription = false;
    @Input() qtySelected = 0;
    @Input() httpParams;
    @Input() cartService;
    @Input() websiteSaleService;
    width = 900;
    height = 800;
    scaleFactor;
    originalScaleFactor = 40;
    seatSizeFactor = 0.4;
    d3 = d3;
    zoom = null;

    seatsHover: SyosSeat[];
    seatSelected: SeatsSelected = <SeatsSelected>{};
    isMobil = false;
    activeSelectSeats = true;
    MIN_SHOW_POLYGON = 0;
    sub = [];
    //venueIds = [1420077251, 1471129903, 1480227252, 1536906552];
    facilityId = [1411850201];
    startZoom = false;


    zoomedV4 = (event) => {
        if (DrawSeatsmapSaleComponent.zoomActive) {
            const t = event.transform;
            const isMobil = DrawSeatsmapSaleComponent._platform.ANDROID || DrawSeatsmapSaleComponent._platform.IOS;

            ZoomService.zoomCurrent = t;
            DrawSeatMapsService.containerSvg.attr('transform', t);
            console.log(event?.sourceEvent?.type);
            if (this.startZoom && !this.seatMapService.smallSeatsMap) {
                const aa = DrawSeatMapsService.getPolygonShowInMap(this.venue);
                console.log(aa);
                DrawSeatsmapSaleComponent.watchStartSeatsManager.next(new Date().getTime());
                if (!DrawSeatMapsService.isPolygonView) {
                    //  console.log("sssssssssssssssssssss",d3.event.sourceEvent.type)

                }

            }


        }
    };

    ngOnInit() {
        //  console.log('ngOnInit');
        this.onSeatsSelected = false;
        let zoom;

        zoom = d3org.zoom()
            .scaleExtent([0.1, 2])
            .on('zoom', this.zoomedV4);

        // console.log(typeof zoom);

        // @ts-ignore
        this.width = document.querySelector('#chartCt').offsetWidth;
        if (this.width < 600) {
            this.height = this.width * 1.3;
        }
        //console.log(this.width);
        DrawSeatMapsService.createConstructor(d3, zoom, this.width, this.height);
        this.drawSeatMap();
        setTimeout(() => {
            this.cartService?.getCart().subscribe();
        }, 2000);

        const a = this.cartService?.cartSub.asObservable().subscribe(value => {
            if (value) {
                DrawSeatMapsService.addReserveTag(value, this.eventId, this.isSubscription);
            }

        });

        const b = this.seatMapService.watchQtySeatMapSelect().subscribe(value => {
            if (this.qtySelected !== value) {
                this.qtySelected = value;
                if (this.qtySelected > 0) {
                    const enableSt = (this.facilityId.find(ids => ids === this.venue.facilityId)) !== undefined;
                    selectAll('.circle-seat')
                        .classed('not-sellable-seat', true);
                    const p = selectAll('.sellable');
                    const pSeat = [];
                    p.each((seatData: { data: any }, j) => {

                        const temp = DrawSeatMapsService.getValidSeatsOnSale(seatData.data, this.qtySelected, this.venue.avoidSingle, enableSt);
                      //console.log(temp)
                        temp.forEach(value1 => {
                            if (pSeat.find(st => st.id === value1.id) === undefined) {
                                pSeat.push(value1);
                            }
                        });
                    });

                    pSeat.forEach(pS => {
                        select('#circle-seat' + pS.id)
                            .classed('not-sellable-seat', false)
                        ;
                    });
                    //console.log(pSeat.length);
                } else {
                    selectAll('.circle-seat')
                        .classed('not-sellable-seat', false);
                }

                this.clearSeats();
            }

            // console.log(value);
        });
        const d = this.seatMapService.watchSubBestSeatSelect().subscribe(value => {
            this.activeSelectSeats = !value;
        });
        const e = this.seatMapService.watchSeatSelected().subscribe(value => {
            if (!value) {
                // this.seatSelected = <SeatsSelected>{};
                // this.onSeatsSelected = false;
            }
        });
        const f = DrawSeatMapsService.watchZoomPolygons.asObservable().subscribe(value => {
            if (value !== null) {
                // console.log('////////////////////////////////////////////');
                setTimeout(() => {
                    DrawSeatsmapSaleComponent.watchStartSeatsManager.next((new Date()).getTime());
                    //   this.setSeatEventClic();
                }, 2000);
            }
        });
        const j = DrawSeatMapsService.watchZoom.asObservable().subscribe(value => {
            //  console.info("watchZoom",value)

        });
        const k = DrawSeatMapsService.listenEventsSeats.asObservable().subscribe(value => {
            if (value) {
                this.manageSeatsEvent(value);
            }

        });
        const l = DrawSeatsmapSaleComponent.watchStartSeatsManager.asObservable().subscribe(value => {

            if (value && !DrawSeatsmapSaleComponent._smallSeatMap) {
                this.doStartEventSeats();
            }

        });

        const p = ZoomService.watchZoom.asObservable().subscribe(value => {
            // console.log(ZoomService);
            const t = ZoomService.bodySvg;

            if (t && ZoomService && ZoomService.instance) {
                ZoomService.zoomCurrent = value.zoomIdentity;
                // @ts-ignore
                // ZoomService.bodySvg.transition()
                //     .duration(1750)
                //     .call(ZoomService.instance.transform, value.zoomIdentity);
                ZoomService.instance.transform(ZoomService.bodySvg, value.zoomIdentity);
            }

        });
        this.sub = [a, b, d, e, f, j, k, l, p];
    }

    ngOnDestroy(): void {
        //console.log('remove seat map');
        this.seatMapService.subSeatSelected.next(null);
        d3.selectAll('.' + DrawSeatMapsService.constSeatMap.SEAT)
            .on('touchstart', null)
            .on('touchmove', null)
            .on('touchcancel', null)
            .on('click', null)
            .on('mouseover', null)
            .on('mouseout', null)
            .remove();
        d3.select('#venue' + this.venue.id).remove();
        this.clearSeats();
        this.sub.forEach(value => {
            value?.unsubscribe();
        });
    }

    doStartEventSeats() {
        const svgVenue = d3.select('#venue' + this.venue.id);
        // DrawSeatMapsService.removeAllSeatsReset()
        DrawSeatMapsService.drawSeatsInPolygonZoomOut(svgVenue, this.venue, this.eventId, this.isSubscription, this.httpParams);

    }

    updateSeatWithPrice() {
        this.venue.levels.forEach((level) => {
            level.sections.forEach((section) => {
                // console.log(this.eventId);
                DrawSeatMapsService
                    .getPriceForSeatBySectionId(this.eventId, this.isSubscription, <SyosSection>{ id: section.id }, this.venue, this.httpParams);

            });
        });
    }

    doCenterSeatsMap() {
        const a = document.querySelector('#chart');
        // @ts-ignore
        const bbox2 = a.getBoundingClientRect();

        const vw = bbox2.width;	// container width
        const vh = bbox2.height;	// container height
        const bbox = DrawSeatMapsService.containerSvg.node().getBBox();
        const bw = bbox.width;
        const bh = bbox.height;
        const x = Math.floor(bbox.x + bbox.width / 2.0);
        const y = Math.floor(bbox.y + bbox.height / 2.0);

        //console.log(bbox2, bbox);
        const scale = Math.max(0, Math.min(8, 0.9 / Math.max(bw / vw, bh / vh)));
        const translate = [vw / 2 - scale * x, vh / 2 - scale * y];
        ZoomService.scaleInit = d3Zoom.zoomIdentity.translate(translate[0], translate[1]).scale(scale);
        ZoomService.watchZoom.next(<ZoomInterface>{ zoomIdentity: d3Zoom.zoomIdentity.translate(translate[0], translate[1]).scale(scale), action: 'm' });
        setTimeout(() => {
            this.startZoom = true;
        }, 2000);

        // DrawSeatMapsService.bodySvg.transition(transition);
        // .call(zoom.translate(translate).scale(scale).event); // not in d3 v4

    }


    manageSeatsEvent(value) {
        if (!value) {
            return;
        }
        if (!this.activeSelectSeats) {
            return;
        }

        if (value.event === 'click') {
            // console.log('click');
            if (this.isMobil) {
                this.clearSeats();
                //** en force les seathover car n'excite pas le event hover mobile ***/
                const website = this.websiteSaleService?.getLocalConfig();
                //const selectionPeers = website.accountCode === 'STE';
                const enableSt = (this.facilityId.find(ids => ids === this.venue.facilityId)) !== undefined;

                this.seatsHover = DrawSeatMapsService.getValidSeatsOnSale(value.data.data, this.qtySelected, this.venue.avoidSingle, enableSt);
                if (this.seatsHover.length > 0) {
                    this.seatsMoseOver();
                }
            }
            // console.log(this.onSeatsSelected);
            this.onSeatsSelected = !this.onSeatsSelected;
            if (this.seatsHover && this.seatsHover.length > 0 && this.onSeatsSelected) {
                this.seatSelected.data = this.seatsHover;

                if (value.data && value.data.priceLevelId && value.data.priceLevelId > 0) {
                    const temp = this.seatMapService.getPriceLevel(this.venue.priceLevels, value.data.priceLevelId);
                    this.seatSelected.prices = temp.prices;
                    const hasNeedSplitPrice = this.seatSelected.prices.length > 1;
                    this.seatSelected.prices.forEach((value1, index) => {
                        /** le premier prix c'est le choix par default.
                         *  s'il y a plusieurs choix le client vas choisir le prix souhaite dans un autre page
                         */
                        if (index === 0) {
                            value1.qty = this.qtySelected;
                            value1.maxQty = this.qtySelected;
                            value1.seatIds = this.seatSelected.data.map(value2 => {
                                const note = DrawSeatMapsService.getNote(value2.id);
                                value2.note = note;
                                return value2.id;
                            });
                            this.seatSelected.seatIds = this.seatSelected.data.map(value2 => value2.id);
                            value1.confirmed = !hasNeedSplitPrice;
                        } else {
                            value1.qty = 0;
                            value1.maxQty = 0;
                        }

                    });
                    this.seatSelected.notes = [];
                    this.seatSelected.seatIds.forEach(idSeat => {
                        const note = DrawSeatMapsService.getNote(idSeat);
                        if (note) {
                            this.seatSelected.notes.push(note);
                        }
                    });
                    this.seatSelected.priceLevelId = value.data.priceLevelId;
                    this.seatSelected.colorPriceLevel = temp.color;
                    if (this.seatSelected.prices.length === 1) {
                        this.seatSelected.data.forEach(value1 => {
                            value1.priceSelected = this.seatSelected.prices[0];
                        });
                    }
                }
                //console.log(value.listener.x,value.listener.y)
                this.seatSelected.left = value.listener.x;
                this.seatSelected.top = value.listener.y;
                //console.log(this.seatSelected);
                this.seatMapService.subSeatSelected.next(this.seatSelected);
            }
            if (!this.onSeatsSelected) {
                this.clearSeats();
            }
        }
        if (value.event === 'mouseover') {
            //console.log('mouseover', this.qtySelected, value.data.data);
            if (DrawSeatMapsService.priceLevelSelected.value) {

                if (value.data && value.data.priceLevelId) {
                    if (DrawSeatMapsService.priceLevelSelected.value !== value.data.priceLevelId) {
                        return;
                    }
                }
            }
            if (!this.onSeatsSelected) {
                console.log(value.data.data);
                const website = this.websiteSaleService?.getLocalConfig();
                // const selectionPeers = website.accountCode === 'STE';

                const enableSt = (this.facilityId.find(ids => ids === this.venue.facilityId)) !== undefined;
                console.log(enableSt);
                const temp = DrawSeatMapsService.getValidSeatsOnSale(value.data.data, this.qtySelected, this.venue.avoidSingle, enableSt);

                this.seatsHover = temp;
                if (this.seatsHover) {
                    this.seatsMoseOver();
                }
            }
        }
        if (value.event === 'mouseout') {
            if (!this.onSeatsSelected) {
                this.seatsMoseOut();
            }

        }
    }


    seatsMoseOver() {
        //  console.log(this.seatsHover);
        this.seatsHover.forEach(function(seat) {
            const circle = DrawSeatMapsService.getSeat(seat.id).select('circle');
            circle.style('fill', 'rgb(255,171,0)');
            circle.style('stroke-width', '1');

        });
    }

    seatsMoseOut() {
        console.log(this.seatsHover);
        if (!this.seatsHover || this.seatsHover.length === 0) {
            return;
        }
        //console.log(this.venue.id);
        this.seatsHover.forEach(function(seat) {
            const seatOb = DrawSeatMapsService.getSeat(seat.id);
            // d3.select("ss").empty()
            if (seatOb.empty()) {
                return;
            }
            const color = seatOb.datum().color;
            const circle = seatOb.select('circle');
            //  console.log(color)
            circle.style('stroke-width', '0.2');
            if (color) {
                circle.style('fill', color);
            }
        });
    }


    drawSeatMap(val?: boolean) {
        //return
        /// console.log('venus ###############', this.venus);
        //  console.log('d3 ###############', d3);
        if (this.venue && this.venue.levels && this.venue.levels.length > 0 && d3) {

            if (val) {
                this.venue.levels.forEach(function(level) {
                    level.sections.forEach(function(section) {
                        const lev = DrawSeatMapsService.getGroup('level', level.id);
                        DrawSeatMapsService.drawSectionPolygons(lev, section, level);
                    });
                });
            } else {
                this.seatSizeFactor = this.venue.seatSizeFactor || this.seatSizeFactor;
                this.scaleFactor = 10;

                //    console.log('****************************************************')
                DrawSeatMapsService.setSeatSizeFactor(this.seatSizeFactor);
                //console.log(this.venue.numberOfSeats);
                if (this.seatMapService.smallSeatsMap) {
                    DrawSeatsmapSaleComponent._smallSeatMap = true;
                    DrawSeatMapsService.drawVenue(this.venue);
                    DrawSeatsmapSaleComponent.watchStartSeatsManager.next((new Date()).getTime());
                    this.updateSeatWithPrice();

                } else {
                    DrawSeatsmapSaleComponent._smallSeatMap = false;
                    DrawSeatMapsService.drawVenuePolygon(this.venue, this.eventId);

                }
                setTimeout(() => {
                    this.doCenterSeatsMap();
                }, 1000);

            }
            this.venue?.priceLevels?.forEach(value => {
                let max = 0;
                let min = 1000000;
                value.prices.forEach(value1 => {
                    if (max < value1.price) {
                        max = value1.price;
                    }
                    if (min > value1.price) {
                        min = value1.price;
                    }
                });
                value.max = max;
                value.min = min;
            });
        }
    }


    clearSeats() {
        this.seatsMoseOut();
        this.seatsHover = null;
        this.onSeatsSelected = false;
        this.seatSelected = <SeatsSelected>{};

        this.seatMapService.subSeatSelected.next(null);

    }

    actionControlZoom($event: any) {
        console.log($event);
        if ($event.action === 'zoomHome') {
            DrawSeatMapsService.removeAllSeatsReset();


        }
    }
}

