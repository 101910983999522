import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SeatsSelected } from '../../models/seats-selected';
import { SyosPriceLevel } from '../../models/syos-price-level';
import { SyosSeatStatus } from '../../models/syos-seat-status';
import { SyosSeatDetail } from '../../models/syos-seat-detail';
import { SyosVenueDetails } from '../../models/syos-venue-details';
import { PriceWeb } from '../../models/price-web';
import { SyosSection } from '../../models/syos-section';


export enum TypeApi {
    FULL = 'full',
    POLYGONS = 'polygons',
    DEFAULT = ''
}

@Injectable({
    providedIn: 'root'
})
export class SeatMapService {

    subQty = new Subject<number>();
    subBestSeat = new Subject<boolean>();
    subSeatSelected = new BehaviorSubject<SeatsSelected>(null);
    MIN_SHOW_POLYGON = 4500; //cette option doit retourne par le backend
    smallSeatsMap = true;
    MIN_SHOW_SEATS = 1500;


    constructor(private http: HttpClient) {
    }

    watchQtySeatMapSelect(): Observable<number> {
        return this.subQty.asObservable();
    }

    watchSubBestSeatSelect(): Observable<boolean> {
        return this.subBestSeat.asObservable();
    }

    watchSeatSelected(): Observable<SeatsSelected> {
        return this.subSeatSelected.asObservable();
    }

    getPriceLevel(priceLevels: SyosPriceLevel[] |any, pricesLevelId): SyosPriceLevel {
        let temp = null;
        if (!priceLevels) {
            return temp;
        }
        priceLevels.forEach((per) => {
            if (per.id === pricesLevelId) {
                temp = per;
                return temp;
            }

        });
        return temp;
    }

    getSeatStatus(seatStatus: SyosSeatStatus[], seatStatusId): SyosSeatStatus {
        let temp = null;
        if (!seatStatus) {
            return temp;
        }
        seatStatus.forEach((sets) => {
            if (sets.id === seatStatusId) {
                temp = sets;
                return temp;
            }
        });
        return temp;
    }

    getPriceForSeatBySectionId(Id, sectionId, isSubscription = false, httpParams ?: HttpParams): Observable<SyosSeatDetail[]> {
        if (!Id) {
            return of([]);
        }
        if (isSubscription) {
            return this.http.get<SyosSeatDetail[]>('v1/seatmap/subscription/' + Id + '/section/' + sectionId + '/details', { params: httpParams });

        }
        return this.http.get<SyosSeatDetail[]>('v1/seatmap/event/' + Id + '/section/' + sectionId + '/details', { params: httpParams });
    }

    getPriceLevelsForSeatsMap(eventId, httpParams ?: HttpParams): Observable<SyosVenueDetails> {
        return this.http.get<SyosVenueDetails>('v1/seatmap/event/' + eventId + '/full', { params: httpParams });

    }

    formatToPrices(seatsSelected: SeatsSelected): PriceWeb[] {
        const prices = [];
        seatsSelected.data.forEach((seat) => {
            const price = seat.priceSelected;
            const key = price.id;
            const temp = prices[key];
            if (temp) {
                prices[key][0].seatIds.push(seat.id);
                prices[key][0].total = 0;
            } else {
                prices[key] = [];
                price.seatIds = [];
                price.seatIds.push(seat.id);
                price.total = 0;
                prices[key].push(price);
            }
        });
        const dataFormPrices: PriceWeb[] = [];

        Object.keys(prices).forEach(function(key) {
            prices[key][0].qty = prices[key][0].seatIds.length;
            prices[key][0].total = (prices[key][0].serviceCharges + prices[key][0].price) * prices[key][0].qty;
            dataFormPrices.push(prices[key][0]);
        });
        return dataFormPrices;
    }

    getPolygons(Id, isSubscription = false, httpParams ?: HttpParams): Observable<SyosVenueDetails> {
        if (isSubscription) {
            return this.http.get<SyosVenueDetails>('v1/seatmap/subscription/' + Id + '/polygons', { params: httpParams });
        }
        return this.http.get<SyosVenueDetails>('v1/seatmap/event/' + Id + '/polygons', { params: httpParams });
    }

    getVenueFull(Id, isSubscription = false, httpParams ?: HttpParams): Observable<SyosVenueDetails> {
        if (!Id) {
            return this.http.get<SyosVenueDetails>('v1/seatmap/event/' + Id + '/full', { params: httpParams });
        }
        if (isSubscription) {
            return this.http.get<SyosVenueDetails>('v1/seatmap/subscription/' + Id + '/full', { params: httpParams });
        }
        return this.http.get<SyosVenueDetails>('v1/seatmap/event/' + Id + '/full', { params: httpParams });
    }

    getVenueAdmin(venueId, isPolygon = true, httpParams = new HttpParams()) {
        if (isPolygon) {
            return this.http.get<SyosVenueDetails>('v1/seatmap/venues/' + venueId + '/polygons', { params: httpParams });
        }
        return this.http.get<SyosVenueDetails>('v1/seatmap/venues/' + venueId + '/full', { params: httpParams });
    }

    getSection(id: string, typeApi?: TypeApi): Observable<SyosSection> {
        const t = 10 * 60 * 1000;
        let httpParams = new HttpParams();
        httpParams = httpParams.set('cache', String(t));
        const temp = typeApi ? '/' + typeApi : '';
        return this.http.get<SyosSection>(`v1/seatmap/sections/${id}${temp}`, { params: httpParams }
        );
    }


}
