import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ClientService } from '../../client.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Address, EnumAddressType } from '../../../models/address';
import { Phone } from '../../../models/phone';
import { WebsiteSaleService } from '../../../shared/services/website-sale.service';
import { MessageAction, MessageObserverService } from '../../../shared/services/message-observer.service';
import { ClientDetails } from '../../../models/client-details';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfigWebsite } from '../../../models/config-website';
import { NewsletterResponse } from '../../../models/newsletter-response';
import { ClientNewsletterComponent, NewsletterType } from '../client-newsletter/client-newsletter.component';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ClientVerificationService } from '../../../shared/services/client-verification.service';

@Component({
    selector: 'tw-client-profil',
    templateUrl: './client-profil.component.html',
    styleUrls: ['./client-profil.component.css']
})
export class ClientProfilComponent implements OnInit {
    profileForm: UntypedFormGroup;
    client: ClientDetails;
    address: Address;
    phone: Phone;
    load = true;
    isProfileGuest = false;
    configWebsite: ConfigWebsite;
    newsletterType: NewsletterType = NewsletterType.PROFILE;
    @ViewChild('newsletterComponent')
    private newsletterComponent: ClientNewsletterComponent;

    @Output() newsletterSubmission: EventEmitter<any> = new EventEmitter();

    constructor(private clientService: ClientService,
                private fb: UntypedFormBuilder,
                private websiteSaleService: WebsiteSaleService,
                private messageObserverService: MessageObserverService,
                private router: Router,
                public  translate: TranslateService,
                private analyticsService: AnalyticsService,
                private xverifyService: ClientVerificationService) {
        this.configWebsite = this.websiteSaleService.getLocalConfig();
        this.profileForm = this.fb.group({
            address: this.fb.group({}),
            phone: this.fb.group({}),
            client: this.fb.group({})
        });
        this.isProfileGuest = router.url.search('profile-guest') !== -1;
        if (this.isProfileGuest && this.configWebsite.clientAccount !== 'NO') {
            this.profileForm.addControl('password', this.fb.control(''));
            this.profileForm.addControl('cPassword', this.fb.control(''));
        }

    }

    static convertToPhone(val): Phone {
        const phone = <Phone>{};
        if (val) {
            phone.number = val.number;

        }
        return phone;
    }

    static convertToAddress(val, type ?: EnumAddressType): Address {
        const address = <Address>{};
        if (val) {
            address.addressLine1 = val.addressLine1;
            address.addressLine2 = val.addressLine2;
            address.appartment = val.appartment;
            address.city = val.city;
            address.zipCode = val.zipCode;
            address.country = val.country;
            address.company = val.company;
            address.unitType = val.unitType;
            if (!type) {
                address.type = EnumAddressType.BILLING;
            } else {
                address.type = type;
            }

            if (val.country === 'CA') {
                address.province = val.statesCAD;
            } else if (val.country === 'US') {
                address.province = val.statesUS;
            } else {
                address.province = val.statesWorld;
            }
        }
        return address;
    }

    ngOnInit() {
        this.client = this.clientService.getClientLocal();
        this.address = this.clientService.getClientLocal().addresses[0];
        this.phone = this.clientService.getClientLocal().phones[0];
        this.analyticsService.trackProfileView();
        this.profileForm.valueChanges.subscribe(val => {
            this.convertToClientRequest(val);
        });
    }


    get addressGroup() {
        return this.profileForm.get('address');
    }

    get clientGroup() {
        return this.profileForm.get('client');
    }

    get phoneGroup() {
        return this.profileForm.get('phone');
    }

    convertToClientRequest(val) {
       // console.log(val);
        const clone = Object.assign({}, val);
        this.client = Object.assign({}, val.client);
        this.client.addresses = [];
        this.client.addresses.push(ClientProfilComponent.convertToAddress(clone.address));
        this.client.phones = [];
        this.client.phones.push(ClientProfilComponent.convertToPhone(clone.phone));
        const cl = this.clientService.getClientLocal();
        this.client.accountId = this.configWebsite.accountId;
        this.client.id = cl.id;
        if (!this.isProfileGuest) {
            this.client.username = this.client.email;
        }
    }

    updateClient() {
        this.load = false;
        if (this.isProfileGuest && this.configWebsite.clientAccount !== 'NO') {
            if (this.profileForm.value.password !== this.profileForm.value.cPassword) {
                this.messageObserverService.addMessage(<MessageAction>
                    { dialog: true, message: 'client.passwordsMustBeSame', translate: true });
                this.load = true;
                return;
            }
        }

        this.xverifyService.validateEmail(this.profileForm.value.client.email).subscribe(value => {
            if (value.email.status === 'invalid') {
                this.load = true;
                this.translate.get('xverify.email.invalid').subscribe(val => {
                    this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: val.replace('%1', value.email.responsecode) });
                });
            } else {
                this.clientService.updateClient(this.client).subscribe(() => {
                    console.log(this.client)
                    if (this.isProfileGuest) {
                        if (this.configWebsite.clientAccount === 'NO') {
                            this.router.navigate([this.translate.currentLang + '/client/middlewareClient']).then(() => {
                            });

                        } else {
                            this.clientService.isLoggedIn(this.configWebsite.accountCode).subscribe(() => {
                                this.router.navigate([this.translate.currentLang + '/client/middlewareClient'])
                                    .then(() => {
                                        this.load = true;
                                    }).catch(error => {
                                    //console.error(error);
                                    this.load = true;
                                    this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: 'success.update', translate: true });

                                });
                            });
                        }

                    } else {
                        this.load = true;
                        this.newsletterComponent.submitNewsletterRequest();
                        this.messageObserverService.addMessage(<MessageAction>{ dialog: false, message: 'success.update', translate: true, snackBar: {} });
                    }
                    this.load = true;

                }, error1 => {
                    this.load = true;
                    this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
                });
            }
        });

    }


}
