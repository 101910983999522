import {Component, OnInit, ViewChild} from '@angular/core';
import {InvoiceService} from '../../invoice.service';
import {ClientService} from '../../client.service';
import {Invoices} from '../../../models/invoices';
import {HttpParams} from '@angular/common/http';
import {AnalyticsService} from '../../../shared/services/analytics.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {lastValueFrom} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Invoice} from '../../../models/invoice';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as moment from "moment";

@Component({
    selector: 'tw-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed,void', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]

})
export class InvoicesComponent implements OnInit {
    invoices: Invoices;
    dataSource: MatTableDataSource<Invoice, any>;
    columnsToDisplay = ['detail'];
    expandedElement: any | null;
    columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
    resultsLength: any;
    columnSelect: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    campaignOne = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });
    constructor(private invoiceService: InvoiceService,
                private clientService: ClientService,
                private analyticsService: AnalyticsService,public translate :TranslateService) {
    }

    async ngOnInit() {
        const client = this.clientService.getClientLocal();
        this.analyticsService.trackInvoicesView();
        let extraParams = new HttpParams().set('clientId', String(client.id));
        extraParams = extraParams.set('includeTickets', 'true');
        extraParams = extraParams.set('limit','150')
        const inv = await lastValueFrom(this.invoiceService.getInvoices(extraParams));
        inv.invoices.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
        });

        this.invoices = inv;
        this.dataSource = new MatTableDataSource(inv.invoices);
        this.paginator._intl.itemsPerPageLabel = this.translate.currentLang === 'fr' ? 'Factures par pages:':'Invoices per pages:';
        this.paginator._intl.nextPageLabel = this.translate.currentLang === 'fr' ? 'Page suivante':'Next page';
        this.paginator._intl.previousPageLabel = this.translate.currentLang === 'fr' ? 'Page précédente':'Previous page';
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.resultsLength = inv.metadata.totalElements;
        this.campaignOne.controls.end.valueChanges.subscribe(value => {
            const newValue = this.invoices.invoices.filter(value1 => {
                const dateA = new Date(value1.date)
                const start = <moment.Moment>this.campaignOne.controls.start.value;
                const end = <moment.Moment>this.campaignOne.controls.end.value;
                if(!start || !end ){
                    return
                }
                console.log('date',dateA,dateA.getTime())
                console.log('start',start,start.toDate().getTime())
                console.log('end',start.toDate().getTime())
                console.log('resulta === ',start.toDate().getTime() <= dateA.getTime() , end.toDate().getTime() >= dateA.getTime())
               return start.toDate().getTime() <= dateA.getTime() && end.toDate().getTime() >= dateA.getTime();

            })
            console.log(newValue)
            this.dataSource.data = newValue;
        })
    }

    detailCell(name: string, element: Invoice) {
        this.columnSelect = name;
        this.expandedElement = this.expandedElement === element ? null : element;
    }

    getDateFormatString():string {
        if (this.translate.currentLang === 'fr') {
            return 'DD/MM/YYYY – DD/MM/YYYY';
        }
        return 'MM/DD/YYYY – MM/DD/YYY'
    }
}
