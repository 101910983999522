




@if (venue) {
  <lib-draw-seatsmap-sale
    [venue]="venue"
    [eventId]="id"
    [isSubscription]="isSubscription"
    [httpParams]="httpParams"
    [cartService]="cartService"
    [websiteSaleService]="websiteSaleService"
  ></lib-draw-seatsmap-sale>
}

@if (!venue) {
  <div class=" tw-padding-10 mat-elevation-z1" style="min-height: 100px;text-align: center">
    @if (!load) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    @if (!load) {
      <span >{{ 'confirmation.transactionFalse' | translate }}</span>
    }
    @if (load) {
      <span >{{ 'notSeatmap' | translate }}</span>
    }
  </div>
}
