import { Inject, Injectable, Optional } from '@angular/core';
import { Configurations } from '../models/configurations';
import {APP_CONFIG} from '../utils/utilsShared';


@Injectable({
    providedIn: 'root'
})
export class SharedConfigService {

    config;


    constructor(@Optional()@Inject(APP_CONFIG) config: Configurations) {
        console.log("===============",config)
        if (config) {
            this.config = config;
        }
    }

    get() {
        return this.config;
    }

}
