/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum EnumAddressType {
    BILLING = 'BILLING',
    OLD = 'OLD',
    COMPANY = 'COMPANY',
}
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum AddressStateEnum {
    UNKNOWN,
    VALID,
    INVALID
}
/**
* @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export enum UnitTypeEnum {
    APARTMENT = 'APARTMENT',
    SUITE = 'SUITE',
    UNIT = 'UNIT'
}
/**
 * @deprecated utilse l'interface  le model de shared-lib/src/lib/models **/
export interface Address {
    AddressType;
    type: EnumAddressType;
    company: string;
    appartment: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    province: string;
    city: string;
    zipCode: string;
    AddressStateEnum: AddressStateEnum;
    comment: string;
    unitType: UnitTypeEnum;
}
