import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TicketElements} from '../models/ticket-elements';
import {MailCategory, TicketDetailToken} from '../models/ticket-detail-token';
import {SubdomainService} from '../shared/services/subdomain.service';
import {GiftTicketDialogComponent} from './component/ticket/client-gift-ticket/client-gift-ticket-dialog/gift-ticket-dialog.component';
// import console from 'node:console';
import {MatDialog} from '@angular/material/dialog';
import {SendTicketAsGiftRequest} from '../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-request';
import {SendTicketAsGiftResponse} from '../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-response';

@Injectable({
    providedIn: 'root'
})
export class TicketsService {
    constructor(private http: HttpClient,
                private subdomainService: SubdomainService)
    {
    }

    getUpcomingTickets(httpParams ?: HttpParams): Observable<TicketElements> {
        if (!httpParams) {
            httpParams = new HttpParams().set('cache', '0');
        } else {
            httpParams = httpParams.set('cache', '0');
        }
        return this.http.get<TicketElements>(`v1/tickets/upcoming/self`, { params: httpParams });
    }

    getTicketsPdfForSodIds(sodIds: number[], mail: MailCategory|string) {
        let url = `${this.subdomainService.getGwtUrl()}/backend/v1/tickets?`;
        let i = 0;
        sodIds.forEach((sodId) => {
            url += `${i === 0 ? '' : '&'}ticket${i}=${sodId}`;
            i++;
        });
        url += `&mail=${mail}`;
        if (url.search('null') !== -1) {
            return;
        }
        return this.http.get(url, { responseType: 'blob' });
    }

    getTicketPdfForPrice(token: TicketDetailToken): Observable<Blob> {
        let url = `${this.subdomainService.getGwtUrl()}/backend/v1/tickets?`;
        url += `ticket0=${token.sodId}`;
        url += `&mail=${token.mailCategory}`;
        if (url.search('null') !== -1) {
            return;
        }
        return this.http.get(url, { responseType: 'blob' });
    }

    getSeatsPdfForEventAndDDId(eventId: number, ddIds: number[]) {
        let url = `${this.subdomainService.getGwtUrl()}/backend/vs.pdf?perf=${eventId}`;
        ddIds.forEach((value, index) => {
            if (value) {
                url = url + '&seat' + index + '=' + value;
            }
            if (url.search('null') !== -1) {
                return;
            }
        });
        return this.http.get(url, { responseType: 'blob' });
    }

    getGoogleWalletLink(link: string) {
        return this.http.get(link, {responseType: 'text'});
    }

    getAppleWalletLink(link: string) {
        return this.http.get(link, {responseType: 'blob'});
    }

    sendTicketAsGift(requestBody: SendTicketAsGiftRequest, httpParams?: HttpParams): Observable<SendTicketAsGiftResponse> {
        const url = `v1/sendasgift`;
        console.log(requestBody)
        return this.http.put<any>(url, requestBody, { params: httpParams });
    }

}
