import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {getRangesDate} from '../../utils/utilsShared';

export interface RangeDates {
    startDate: Date;
    endDate: Date;
    showTime?: boolean;
}

@Component({
    selector: 'tw-date-range-select-advanced',
    templateUrl: './date-range-select-advanced.component.html',
    styleUrls: ['./date-range-select-advanced.component.css']
})
export class DateRangeSelectAdvancedComponent implements OnInit {

    @Output() rangeDate: EventEmitter<RangeDates> = new EventEmitter<RangeDates>();
    @Input() hasRangDate = true;
    @Input() hasButton = true;
    @Input() defaultDate = 3;
    @Input() matInputStyle;
    @Input() startDefaultTime = '00:00';
    @Input() endDefaultTime = '23:59';
    @Input() hasTime = false;

    @Input() set _styleDate(val) {

        if (!val || val === 1) {
            this.styleDate = 1;
            this.currentTypeRange = 'other';
            if(!this.typeRange || this.typeRange.length === 0){
                this.typeRange = ['today', 'nextWeek', 'previousWeek', 'other', 'nextMonth', 'previousMonth', 'nextYear', 'previousYear', 'next2Year', 'previous2Year'];

            }
        }
        if (val === 0) {
            this.styleDate = 1;
            this.defaultDate = 0;
            this.currentTypeRange = 'today';
            if(!this.typeRange || this.typeRange.length === 0){
                this.typeRange = ['today', 'nextWeek', 'nextMonth', 'other', 'previousWeek', 'previousMonth'];
            }

        }
        if (val === 2) {
            this.defaultDate = 2;
            this.currentTypeRange = 'nextMonth';
            this.styleDate = 2;
            if(!this.typeRange || this.typeRange.length === 0){
                this.typeRange = ['today', 'nextWeek', 'nextMonth', 'other'];
            }

        }
        //console.log(this.typeRange)
    };

    styleDate = 1;
    @Input() startDate: Date = new Date();
    @Input() endDate: Date = new Date();
    startDateCtr: UntypedFormControl = new UntypedFormControl();
    endDateCtr: UntypedFormControl = new UntypedFormControl();
    typeRangeControl: UntypedFormControl = new UntypedFormControl();
    @Input() currentTypeRange: 'today' | 'nextWeek' | 'nextMonth' | 'other' | 'previousWeek' | 'previousMonth' = 'other';
    @Output() currentTypeRangeChange = new EventEmitter();
    @Input() typeRange = [];
    submitButtonLabel: string;

    constructor(private translate: TranslateService) {
        this.translate.get('formMsg.submit').subscribe(val => {
            this.submitButtonLabel = val;
        });
    }

    ngOnInit() {


        this.startDateCtr.setValue(this.startDate);
        this.startDateCtr.disable();
        this.endDateCtr.setValue(this.endDate);
        this.endDateCtr.disable();
        this.startDateCtr.valueChanges.subscribe(value => {
            this.startDate = value;
            if (!this.hasRangDate) {
                this.getDataBy();
            }
            if (!this.hasButton) {
                this.getDataBy();
            }
        });
        this.endDateCtr.valueChanges.subscribe(value => {
            this.endDate = value;
            if (!this.hasButton) {
                this.getDataBy();
            }

        });
        this.typeRangeControl.setValue(this.typeRange[this.defaultDate]);
        if (this.defaultDate !== 3) {
            this.changeDateRange(this.typeRange[this.defaultDate]);
        }

        this.getDataBy();

        this.typeRangeControl.valueChanges.subscribe(value => {
            this.changeDateRange(value);
            if (value !== 'other' || !this.hasButton) {
                this.getDataBy();
            }
        });
        setTimeout(() => {
            //console.log(this.currentTypeRange, this.typeRange);
            this.typeRangeControl.setValue(this.typeRange.find(value => value === this.currentTypeRange));
        }, 1000);

        const div = document.getElementById('data-rang-style-1');
        const observer = new MutationObserver(() => {
            // Fonction pour appliquer les styles en fonction de la taille actuelle de la div
            const width = div.offsetWidth;
            if (width < 400) {

            } else if (width < 800) {

            } else {

            }
        });

    }

    changeDateRange(type) {
        const someDate = new Date();
        this.currentTypeRange = type;
        this.currentTypeRangeChange.next(type);

        const rang = getRangesDate(type);
        this.startDate = rang?.startDate;
        this.endDate = rang?.endDate;
        if (this.startDate) {
            this.startDateCtr.setValue(this.startDate);
            this.endDateCtr.setValue(this.endDate);
        } else {
            if (type === 'other') {
                this.startDateCtr.enable();
                this.endDateCtr.enable();
            }
        }

    }

    getDataBy() {
        if (this.startDate instanceof Date) {
            this.startDate.setHours(this.convertHourString(this.startDefaultTime).hours,
                this.convertHourString(this.startDefaultTime).minutes, 0, 0);
        }
        if (this.endDate) {
            this.endDate.setHours(this.convertHourString(this.endDefaultTime).hours,
                this.convertHourString(this.endDefaultTime).minutes, 0, 0);
        }
        this.rangeDate.emit(<RangeDates>{startDate: this.startDate, endDate: this.endDate});
    }

    getWeek(): Date[] {
        const curr = new Date();
        const week = [];

        for (let i = 1; i <= 7; i++) {
            const first = curr.getDate() - curr.getDay() + i;
            const day = new Date(curr.setDate(first));
            week.push(day);
        }
        //console.log(week);
        return week;
    }

    endTimeChange(val: string) {
        //console.log(val);
        this.endDefaultTime = val;
        if (this.endDate instanceof Date) {
            this.endDate.setHours(this.convertHourString(val).hours, this.convertHourString(val).minutes, 0, 0);
            //console.log(this.endDate);
            if (!this.hasButton) {
                this.getDataBy();
            }
        }
    }


    convertHourString(val): { hours: number, minutes: number } {
        if (!val) {
            return {
                hours: 12, minutes: 0
            };
        }
        const temp = val.split(':');
        return {
            hours: temp[0], minutes: temp[1]
        };
    }

    close() {

    }

    startTimeChange(val: string) {
        //console.log(val);
        this.startDefaultTime = val;
        if (this.startDate instanceof Date) {
            this.startDate.setHours(this.convertHourString(val).hours, this.convertHourString(val).minutes, 0, 0);
            //console.log(this.startDate);
            if (!this.hasRangDate) {
                this.getDataBy();
            }
            if (!this.hasButton) {
                this.getDataBy();
            }
        }
    }
}
