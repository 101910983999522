

<mat-accordion class="client-invoice">
  <mat-expansion-panel  (opened)="invoiceOpenDetail()">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
      <!--            <mat-panel-title>-->
      <!--                <h4>{{invoice.date | twDatePipe}}</h4>-->
    <!--            </mat-panel-title>-->
    <mat-panel-description>
      <div class="tw-flex tw-row tw-header-show " style="margin:15px 0;width: 100%" >

        <div  class="tw-header-font" style="position: relative">
                        <div style="position: absolute;right: -8px;top:-8px;
      display: flex;flex-direction: row;
      justify-content: center;align-items: center;">
            <mat-icon color="primary">local_activity</mat-icon>
            @if (invoice.ticketsQty>0) {
              <span class="tw-header-number">{{invoice.ticketsQty}}</span>
            }
            @if (invoice.returnedTicketsQty>0) {
              <span class="tw-header-number">{{invoice.returnedTicketsQty}}</span>
            }

          </div>

          <div style="font-weight: 600">
            {{"invoice.invoiceNumber"|translate}} : {{invoice.id}}
          </div>
          <div style="font-weight: 600">
            {{"invoice.date"|translate}} : {{invoice.date | twDatePipe:'longDate'}} {{invoice.date | twDatePipe:'shortTime'}}
          </div>
          <div>
            {{"invoice.ticket.serviceCharges"|translate}} : {{invoice.serviceCharge/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
          <div>
            {{"invoice.total"|translate}} : {{invoice.totalAmount/100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>

          @if (invoice.paymentMode.length>0) {
            <div>
              {{"invoice.payModes"|translate}} :
              @for (paymode of invoice.paymentMode; track paymode) {
                <span>
                  {{ paymode }}
                </span>
              }
            </div>
          }
          <!--
          <div *ngIf="price">
            {{price.priceType}}
          </div>
          -->
        </div>

      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  @if (invoice.htmlReport) {
    <div
      style="overflow: auto"
    [innerHTML]="invoice.htmlReport | transformHtml"></div>
  }
</mat-expansion-panel>

@if (invoice.ticketsQty > 0) {
  <mat-expansion-panel [disabled]="invoice.ticketsQty === 0">
    <mat-expansion-panel-header>
      <mat-panel-title >
        <h4>{{'title.ticket' | translate}}s</h4>
      </mat-panel-title>
      <mat-panel-description>
        <h4>{{invoice.ticketsQty}}</h4>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      @for (inv of invoice.tickets; track inv) {
        <!--                <pre>{{inv.seatJson | json}}</pre>-->
        <ng-container
        *ngTemplateOutlet="ticketTemplate;context:{$implicit: inv} "></ng-container>
        <mat-divider></mat-divider>
      }
    </div>
  </mat-expansion-panel>
}
@if (invoice.returnedTicketsQty > 0) {
  <mat-expansion-panel
    [disabled]="invoice.returnedTicketsQty===0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4>{{'title.refund' | translate}}</h4>
      </mat-panel-title>
      <mat-panel-description>
        {{invoice.returnedTicketsQty}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      @for (invRet of invoice.returnedTickets; track invRet) {
        <ng-container
        *ngTemplateOutlet="ticketTemplate;context:{$implicit: invRet} "></ng-container>
        <mat-divider></mat-divider>
      }
    </div>
  </mat-expansion-panel>
}

</mat-accordion>



<ng-template #seatTemplate let-seat >
  @if (seat) {
    <div class="tw-flex tw-row seat-template">
      @if (seat.level) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'client.tickets.level' | translate}}
          </div>
          <div>
            {{seat.level}}
          </div>
        </div>
      }
      @if (seat.section) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'client.tickets.section' | translate}}
          </div>
          <div>
            {{seat.section}}
          </div>
        </div>
      }
      @if (seat.row) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'client.tickets.row' | translate}}
          </div>
          <div>
            {{seat.row}}
          </div>
        </div>
      }
      @if (seat.seat) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'client.tickets.seat' | translate}}
          </div>
          <div>
            {{seat.seat}}
          </div>
        </div>
      }
    </div>
  }
</ng-template>
<ng-template #ticketTemplate let-ticket >
  <div style="margin: 15px 0;padding: 5px 15px;background: rgba(0,0,0,0.04)">
    <h2> {{ticket.eventName}}</h2>
    <h4> {{ticket.pricelevel}}</h4>
    <h4> {{ticket.priceType}}</h4>
    <div class="tw-flex tw-row" style="    justify-content: space-around;">
      @if (ticket.price) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'invoice.ticket.price' | translate}}
          </div>
          <div>
            {{ticket.price /100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      @if (ticket.serviceCharge) {
        <div class="tw-flex tw-column seat-elm">
          <div class="seat-header">
            {{'title.serviceCharge' | translate}}
          </div>
          <div>
            {{ticket.serviceCharge /100 | currency :'':'symbol-narrow':'':translate.currentLang}}
          </div>
        </div>
      }
      <div  class="tw-flex tw-column seat-elm">
        <div class="seat-header">
          Total
        </div>
        <div>
          {{(ticket.price+ticket.serviceCharge) /100 | currency :'':'symbol-narrow':'':translate.currentLang}}
        </div>
      </div>
    </div>
    <ng-container
    *ngTemplateOutlet="seatTemplate;context:{$implicit: ticket.seatJson} "></ng-container>
  </div>

</ng-template>

<!--<ng-container-->
<!--    *ngTemplateOutlet="seatTemplate;context: myContext"></ng-container>-->
