<div class="seatmap-and-control">


    <sh-control-zoom
        class="tw-control-zoom"
        svgContainerId="svg-container"
        svgBodyId="chart"
        [width]="width"
        [height]="height"
        right="5px"
        (actionControl)="actionControlZoom($event)"

    ></sh-control-zoom>
    <div id="chart"
         [ngStyle]="{'width':width+'px' }" style="background: #fdfdfd"></div>


</div>
<!--<div style="position: relative">-->
<!--    <div *ngIf="seatSelected.data" style="position: absolute;z-index: 9;height: 100%;background: white;width: 100%" >-->

<!--        <tw-add-seats-to-cart  [detailsSeatsSelected]="seatSelected" (clearSeatSelect)="clearSeats($event)"></tw-add-seats-to-cart>-->
<!--    </div>-->

<!--    <div class="tw-flex tw-row">-->
<!--        <div style="flex-grow: 6" id="chartCt">-->


<!--        </div>-->
<!--        <tw-control-zoom-->
<!--            svgContainerId="svg-container"-->
<!--            [width]="width"-->
<!--            [height]="height"-->

<!--        ></tw-control-zoom>-->
<!--        <div id="chart" [ngStyle]="{'width':width+'px','background-image':'url(./assets/resources/svg/seatmap.png)' }"></div>-->

<!--        <div style="flex-grow: 2">-->
<!--            <tw-bi-tool-bar-price-level   (qtyEmitter)="getQty($event)" [priceLevels]="venue.priceLevels"></tw-bi-tool-bar-price-level>-->

<!--        </div>-->

<!--    </div>-->


<!--</div>-->

