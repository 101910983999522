import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginClientComponent } from './component/login-client/login-client.component';
import { ClientForgotPasswordComponent } from './component/client-forgot-password/client-forgot-password.component';
import { ClientChangePasswordComponent } from './component/client-change-password/client-change-password.component';
import { ClientProfilComponent } from './component/client-profil/client-profil.component';
import { InvoicesComponent } from './component/invoices/invoices.component';
import { InvoiceViewComponent } from './component/invoice-view/invoice-view.component';
import { ClientTicketsComponent } from './component/ticket/client-tickets/client-tickets.component';
import { SharedModule } from '../shared/shared.module';
import { ShippingModule } from '../shipping/shipping.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material';
import { ClientRoutingModule } from './client-routing/client-routing.module';
import { ClientSignupComponent } from './component/client-signup/client-signup.component';
import { AddressModule } from '../address/address.module';
import { WebsiteSaleService } from '../shared/services/website-sale.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LangsObserverService } from '../shared/services/langs-observer.service';
import { ClientSelectCheckoutMethodComponent } from './component/client-select-checkout-method/client-select-checkout-method.component';
import { MiddlewareClientComponent } from './component/middleware-client/middleware-client.component';
import { ClientInvoiceHeaderComponent } from './component/client-invoice-header/client-invoice-header.component';
import { ClientTicketsSummaryComponent } from './component/ticket/client-tickets-summary/client-tickets-summary.component';
import { ClientTicketDetailComponent } from './component/ticket/client-ticket-detail/client-ticket-detail.component';
import { ClientTicketHeaderComponent } from './component/ticket/client-ticket-header/client-ticket-header.component';
import { ClientTicketDetailsComponent } from './component/ticket/client-ticket-details/client-ticket-details.component';
import { ClientTicketLineComponent } from './component/ticket/client-ticket-line/client-ticket-line.component';
import { CartModule } from '../cart/cart.module';
import { ClientNewsletterComponent } from './component/client-newsletter/client-newsletter.component';
import { ClientGuestComponent } from './component/client-guest/client-guest.component';
import { ClientInvoiceCheckoutComponent } from './component/client-invoice-checkout/client-invoice-checkout.component';
import { FlexPassComponent } from './component/flex-pass/flex-pass.component';
import { FlexPassFilmsComponent } from './component/flex-pass-films/flex-pass-films.component';
import {  MatAutocompleteModule } from '@angular/material/autocomplete';
import { ListFlexPassComponent } from './component/list-flex-pass/list-flex-pass.component';
import { MessageObserverService } from '../shared/services/message-observer.service';
import {ShowPaswwordModule} from '../../../projects/shared-lib/src/lib/component/show-paswword/show-paswword.module';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ShippingModule,
        ReactiveFormsModule,
        MaterialModule,
        ClientRoutingModule,
        AddressModule,
        TranslateModule,
        CartModule,
        MatAutocompleteModule,
        ShowPaswwordModule,
        MatTableModule,
        MatPaginatorModule
    ],
    declarations: [
        LoginClientComponent,
        ClientForgotPasswordComponent,
        ClientChangePasswordComponent,
        ClientProfilComponent,
        InvoicesComponent,
        InvoiceViewComponent,
        ClientTicketsComponent,
        ClientSignupComponent,
        ClientSelectCheckoutMethodComponent,
        MiddlewareClientComponent,
        ClientInvoiceHeaderComponent,
        ClientTicketsSummaryComponent,
        ClientTicketDetailComponent,
        ClientTicketHeaderComponent,
        ClientTicketDetailsComponent,
        ClientTicketLineComponent,
        ClientNewsletterComponent,
        ClientGuestComponent,
        ClientInvoiceCheckoutComponent,
        FlexPassComponent,
        FlexPassFilmsComponent,
        ListFlexPassComponent
    ],
    exports: [
        LoginClientComponent,
        ClientForgotPasswordComponent,
        ClientChangePasswordComponent,
        ClientProfilComponent,
        InvoicesComponent,
        InvoiceViewComponent,
        ClientTicketsComponent,
        ClientSignupComponent,
        ClientSelectCheckoutMethodComponent,
        MiddlewareClientComponent,
        ClientInvoiceHeaderComponent,
        ClientTicketsSummaryComponent,
        ClientTicketDetailComponent,
        ClientTicketHeaderComponent,
        ClientTicketDetailsComponent,
        ClientTicketLineComponent,
        ClientNewsletterComponent,
        ClientGuestComponent,
        ClientInvoiceCheckoutComponent,
        FlexPassComponent,
        FlexPassFilmsComponent,
        ListFlexPassComponent
    ]
})
export class ClientModule {
    constructor(private websiteSaleService: WebsiteSaleService, private translate: TranslateService, private langsObserverService: LangsObserverService) {
        this.langsObserverService.watchLang().subscribe(value => {
            this.translate.use(value);
        });
    }
}
