<!--<div style="margin: auto;max-width: 600px;" *ngIf="invoices && invoices.invoices.length >0 ; else empty" >-->

<!--    <div class="tw-margin-5" *ngIf="invoices.invoices.length>0" >-->
<!--        <h2><b>{{ 'title.invoices' | translate }}</b></h2>-->
<!--        <ng-template ngFor let-invoice [ngForOf]="invoices.invoices" let-i="index" >-->
<!--            <tw-invoice-view [invoice]="invoice"></tw-invoice-view>-->
<!--        </ng-template>-->
<!--    </div>-->
<!--</div>-->
<ng-template #empty>
  <div class="tw-center-600 tw-padding-10 mat-elevation-z1" style="min-height: 100px;text-align: center">
    {{ 'invoice.emptyList' | translate }}
  </div>
</ng-template>
<div class="invoice-table-container mat-elevation-z2 tw-margin-10" >
  <table mat-table
    [dataSource]="dataSource" multiTemplateDataRows
    class=" invoice-table" tabindex="0">

    <ng-container matColumnDef="detail" >
      <th mat-header-cell style="width:300px" *matHeaderCellDef>
                <div class="tw-flex tw-flex-wrap" style="    justify-content: space-between;
    align-items: center;">
          <div>
            <h2>{{ 'title.invoices' | translate }}</h2>
          </div>
          @if (false) {
            <div>
              <mat-form-field class="example-form-field" style="min-width: 300px">
                <mat-label>{{'formMsg.filter' | translate}}</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="dateOnePicker"
                  >
                  <input matStartDate placeholder="{{'date.startDate' | translate}}" formControlName="start" [disabled]="true">
                  <input matEndDate placeholder="{{'date.endDate' | translate}}" formControlName="end" [disabled]="true">
                </mat-date-range-input>
                <!--                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>-->
                <mat-hint>{{getDateFormatString()}}</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="dateOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #dateOnePicker ></mat-date-range-picker>
              </mat-form-field>
            </div>
          }
        </div>




      </th>
      <td mat-cell *matCellDef="let element" style="width:300px">
        <div class=""  style="position: relative; margin: 15px 0" >
          <tw-client-invoice-header [invoice]="element" ></tw-client-invoice-header>
          @if (false) {
            <button
                            style="    position: absolute;
    bottom: 0px;
    right: 6px;"
              mat-icon-button aria-label="expand row"
              (click)="(detailCell('detail',element)); $event.stopPropagation()">
              @if (expandedElement !== element) {
                <mat-icon>keyboard_arrow_down</mat-icon>
              }
              @if (expandedElement === element) {
                <mat-icon>keyboard_arrow_up</mat-icon>
              }
            </button>
          }
        </div>

      </td>
    </ng-container>


    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail" >
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
        <div class="invoice-element-detail"
          [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          //<h1>{{this.columnSelect}}</h1>
          {{element | json}}
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="invoice-element-row"
      [class.invoice-expanded-row]="expandedElement === element"
      >
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="invoice-detail-row"></tr>
  </table>
  <mat-paginator
  [length]="resultsLength" [pageSize]="10" aria-label="Select page of GitHub search results"></mat-paginator>


</div>

