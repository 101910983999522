import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {SessionLocaleService} from './session-locale.service';
import {map} from 'rxjs/operators';
import {RefreshTokenResponse} from '../../models/refresh-token-response';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserService {
    private subjectRqt = new Subject<any>();


    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService) {
    }

    watchActionAuth(): Observable<any> {
        return this.subjectRqt.asObservable();
    }

    loginUser(body: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/refresh-tokens', body, {params: httpParams}).pipe(
            map(value => {
                this.addSessionRefreshToken(value);
              //  this.subjectRqt.next('ok');
            })
        );
    }

    accounts(): Observable<any> {
        return this.http.get<any>('v1/accounts');
    }

    logoutUser(): Observable<any> {
        return of(this.deleteSessionRefreshToken());
    }

    addSessionRefreshToken(val) {
        SessionLocaleService.putSync('rf', val, true);
        this.subjectRqt.next('ok');
    }

    deleteSessionRefreshToken() {
        SessionLocaleService.deleteByKey('rf', true);
        this.deleteAccountSelected();
        this.subjectRqt.next('ok');
        return true;
    }

    getSessionRefreshToken(): RefreshTokenResponse {
        return SessionLocaleService.getSync('rf', true);
    }

    isExpirationRefreshToken(): boolean {
        const ref = this.getSessionRefreshToken();
        if (!ref) {
            return true;
        }
        if (ref && ref.expiration) {
            const now = new Date();
            const expiration = new Date(ref.expiration);
            return expiration.getTime() <= now.getTime();
        }
        return true;
    }

    addAccountSelected(account) {
        SessionLocaleService.putSync('accountSelected', account, true);
    }

    getAccountSelected() {
        return SessionLocaleService.getSync('accountSelected', true);
    }

    deleteAccountSelected() {
        SessionLocaleService.deleteByKey('accountSelected', true);
    }

    isMember(){
        return SessionLocaleService.getSync('member', true);
    }




}
