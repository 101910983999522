import { Component, Input, OnInit } from '@angular/core';
import { SeatMapService } from '../seat-map.service';
import { ActivatedRoute } from '@angular/router';

import { HttpParams } from '@angular/common/http';
import { SyosVenueDetails } from '../../../models/syos-venue-details';
import { HelpsService } from '../../../utils/helps.service';
import { lastValueFrom } from 'rxjs';
import { ItemHour } from '../../../models/item-hour';


@Component({
    selector: 'lib-seat-map-container',
    templateUrl: './seat-map-container.component.html',
    styleUrls: ['./seat-map-container.component.css']
})
export class SeatMapContainerComponent implements OnInit {
    @Input() numberOfSeats;
    @Input() performancesId;
    @Input() subscriptionId;
    @Input() httpParams: HttpParams;
    @Input() cartService: any;
    @Input() websiteSaleService;
    @Input() venueId;
    @Input() itemHour: ItemHour | any;


    venue: SyosVenueDetails;
    isSubscription = false;
    id;

    /** il faut l enlever des que la tache TKP-2166 ET FINI */
        // @Input() priceLevels: SyosPriceLevel[];
    load = true;

    constructor(public seatMapService: SeatMapService, private route: ActivatedRoute) {
        //console.log("===========",config)
    }

    async ngOnInit() {
        const performancesId = this.route.snapshot.params['performancesId'];
        if (performancesId) {
            this.id = performancesId;
        }
        if (this.performancesId) {
            this.id = this.performancesId;
        }
        if (this.subscriptionId) {
            this.id = this.subscriptionId;
            this.isSubscription = true;
        }

        await this.getSeatMap();
    }

    async getSeatMap() {
        try {
            //console.log("load seat maps")
            const t = 10 * 60 * 100;
            this.venue = null;
            let httpParams = HelpsService.createHttpParams(this.route.snapshot.queryParams, this.httpParams);
            httpParams = httpParams.set('cache', String(t));

            this.load = false;
            let value;
            if (!this.numberOfSeats || this.numberOfSeats === 0 || this.numberOfSeats < this.seatMapService.MIN_SHOW_POLYGON) {
                this.seatMapService.smallSeatsMap = true;
            } else {
                this.seatMapService.smallSeatsMap = false;
            }
            if (this.id) {
                if (this.seatMapService.smallSeatsMap) {
                    value = await lastValueFrom(this.seatMapService.getVenueFull(this.id, this.isSubscription, httpParams));
                    // this.updatePrice(value);
                    //this.load = true;
                } else {
                    value = await lastValueFrom(this.seatMapService.getPolygons(this.id, this.isSubscription, httpParams));
                    //this.updatePrice(value);
                    // this.load = true;
                }
            } else {
                value = await lastValueFrom(this.seatMapService.getVenueAdmin(this.venueId, !this.seatMapService.smallSeatsMap, httpParams));
            }
            this.updatePrice(value);
            this.load = true;


        } catch (e) {
            this.load = true;
        }


    }

    updatePrice(value) {
        this.venue = value;
        /** il faut l enlever des que la tache TKP-2166 ET FINI */
        // if (this.priceLevels) {
        //     this.venue.priceLevels = this.priceLevels;
        // }
        /** fin */
        if (this.itemHour?.priceLevels) {
            this.venue.priceLevels = this.itemHour.priceLevels;
        }

        this.venue.priceLevels.forEach(pl => {
            if (pl.prices && pl.prices.length === 1) {
                pl.prices[0].confirmed = true;
            }
        });
        //console.log(this.venue.priceLevels);
    }

}
