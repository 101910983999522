import {DatePipe} from '@angular/common';
import {Sta} from '../models/address';
import {constSystem} from './const-system';

import {SupportedNetworksEnum} from '../models/supported-networks-enum';

import {RangeDates} from '../component/date-range-select-advanced/date-range-select-advanced.component';
import {ThemePalette} from '../models/config-website';
import {InjectionToken, Injector} from '@angular/core';
import {Configurations} from '../models/configurations';

export const APP_CONFIG = new InjectionToken<Configurations>('config-environment');

export const PROJECT_NAME = 'PROJECT_NAME';

export enum ProjectNameEnum {
    ADMIN,
    POS,
    SALE,
    REPORT
}

export const delayTemp = ms => new Promise(resolve => setTimeout(resolve, ms));

export const datePipeAction = (value, format?, translate?) => {

    const datePipe = new DatePipe(translate.currentLang);
    return datePipe.transform(value, format, '', translate.currentLang);
};
export const getAbrCountry = (val: string): string => {
    const country = <Sta[]>constSystem.world.countries;
    const statesUS = <Sta[]>constSystem.world.states;
    const statesCAD = <Sta[]>constSystem.world.provinces;
    if (!val) {
        return 'CA';
    }
    val = val.toLowerCase();
    const temp = country.find(value => value.name.toLowerCase() === val || value.ab.toLowerCase() === val);
    if (temp) {
        return temp.ab;
    }
    return '';
};

export const getAbrProvince = (val: string, isUsa?: boolean): string => {
    let temp: Sta = <Sta>{};
    const country = <Sta[]>constSystem.world.countries;
    const statesUS = <Sta[]>constSystem.world.states;
    const statesCAD = <Sta[]>constSystem.world.provinces;
    temp.ab = '';
    if (!val) {
        return temp.ab;
    }
    val = val.toLowerCase();

    if (isUsa) {
        temp = statesUS.find(value =>
            value.nameEn.toLowerCase() === val
            || value.ab.toLowerCase() === val);
    } else {
        temp = statesCAD.find(value =>
            value.nameEn.toLowerCase() === val
            || value.nameFr.toLowerCase() === val
            || value.ab.toLowerCase() === val);
    }
    if (temp) {
        return temp.ab;
    } else {
        return '';
    }

};
export const getRangesDate = (type): RangeDates => {
    const someDate = new Date();
    let rangeDates: RangeDates = {
        startDate: new Date(),
        endDate: new Date()
    };
    switch (type) {
        case 'today':
            rangeDates.startDate = new Date(someDate.getFullYear(), someDate.getMonth(), someDate.getDate(), 0, 0);
            rangeDates.endDate = new Date(someDate.getFullYear(), someDate.getMonth(), someDate.getDate()+1, 23, 59);

            break;
        case 'nextWeek':
            someDate.setDate(someDate.getDate() + 7);
            rangeDates.startDate = new Date();
            rangeDates.endDate = someDate;

            break;
        case 'nextMonth':
            someDate.setDate(someDate.getDate() + 31);
            rangeDates.startDate = new Date();
            rangeDates.endDate = someDate;

            break;
        case 'previousWeek':
            someDate.setDate(someDate.getDate() - 7);
            rangeDates.startDate = someDate;
            rangeDates.endDate = new Date();

            break;
        case 'previousMonth':
            someDate.setDate(someDate.getDate() - 31);
            rangeDates.startDate = someDate;
            rangeDates.endDate = new Date();

            break;
        case 'all':
            rangeDates.startDate = null;
            rangeDates.endDate = null;
            break;
        case 'thisWeek':
            rangeDates.startDate = getWeek()[0];
            rangeDates.endDate = getWeek()[6];

            break;
        case 'thisMonth':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(rangeDates.startDate.getFullYear(), rangeDates.startDate.getMonth() + 1, 0);

            break;
        case 'nextYear':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(rangeDates.startDate.getFullYear() + 1, rangeDates.startDate.getMonth(), rangeDates.startDate.getDate() );

            break;
        case 'previousYear':
            rangeDates.endDate = new Date();
            rangeDates.startDate = new Date(rangeDates.endDate.getFullYear() - 1, rangeDates.endDate.getMonth(), rangeDates.endDate.getDate());

            break;
        case 'next2Year':
            rangeDates.startDate = new Date();
            rangeDates.endDate = new Date(rangeDates.startDate.getFullYear() + 2, rangeDates.startDate.getMonth(), rangeDates.startDate.getDate());

            break;
        case 'previous2Year':
            rangeDates.endDate = new Date();
            rangeDates.startDate = new Date(rangeDates.endDate.getFullYear() - 2, rangeDates.endDate.getMonth(),  rangeDates.endDate.getDate());

            break;
        default:
            rangeDates = null;
            break;
    }
    return rangeDates;
};
export const getWeek = (): Date[] => {
    const curr = new Date();
    const week = [];

    for (let i = 1; i <= 7; i++) {
        const first = curr.getDate() - curr.getDay() + i;
        const day = new Date(curr.setDate(first));
        week.push(day);
    }
    //console.log(week);
    return week;
};
export const injectCss = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('link');
        script.href = src;
        script.rel = 'stylesheet';
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () => reject('Script loading aborted.'));
        document.head.appendChild(script);
    });
};
export const injectScript = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.addEventListener('load', resolve);
        script.addEventListener('error', () => reject('Error loading script.'));
        script.addEventListener('abort', () => reject('Script loading aborted.'));
        document.head.appendChild(script);
    });
};

export const getGeolocation = (): Promise<GeolocationPosition> => {

    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                    if (position) {
                        resolve(position);
                    }
                },
                (error: GeolocationPositionError) => reject(error)
            );
        } else {
            reject('Geolocation is not supported by this browser.');
        }
    });
};
export const ToNetworkType = (val: string) => {
    if (val.toLowerCase() === 'visa') {
        return SupportedNetworksEnum.VISA;
    }
    if (val.toLowerCase() === 'amex') {
        return SupportedNetworksEnum.AMEX;
    }
    if (val.toLowerCase() === 'discover') {
        return SupportedNetworksEnum.DISCOVER;
    }
    if (val.toLowerCase() === 'mastercard') {
        return SupportedNetworksEnum.MASTERCARD;
    }
    if (val.toLowerCase() === 'interac') {
        return SupportedNetworksEnum.INTERAC;
    }
    return null;
};

export const getAllowedCardNetworks = (typeModePayment: ThemePalette | any): string[] => {
    const types: string[] = [];
    if (typeModePayment.amex) {
        types.push('amex');
    }
    if (typeModePayment.mastercard) {
        types.push('mastercard');
    }
    if (typeModePayment.visa) {
        types.push('visa');
    }
    if (typeModePayment.discover) {
        types.push('discover');
    }
    if (typeModePayment.interac) {
        types.push('interac');
    }
    return types;
};

export type Coordinate = {
    lat: number;
    lon: number;
};
export const getDistanceBetweenTwoPoints = (cord1: Coordinate, cord2: Coordinate) => {
    if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
        return 0;
    }

    const radLat1 = (Math.PI * cord1.lat) / 180;
    const radLat2 = (Math.PI * cord2.lat) / 180;

    const theta = cord1.lon - cord2.lon;
    const radTheta = (Math.PI * theta) / 180;

    let dist =
        Math.sin(radLat1) * Math.sin(radLat2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);

    if (dist > 1) {
        dist = 1;
    }

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //convert miles to km

    return dist;
};
export const sendToBackendDevice = (actionsConnect) => {
    // @ts-ignore
    if (window?.ReactNativeWebView) {
        // @ts-ignore
        window?.ReactNativeWebView.postMessage(JSON.stringify(actionsConnect));
    }

};
export const uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};
export const supprimerParametreURL = (url, parametreASupprimer) => {
    const urlObj = new URL(url);
    const searchParams = urlObj.searchParams;

    searchParams.delete(parametreASupprimer);

    // Reconstruire l'URL avec les paramètres mis à jour
    urlObj.search = searchParams.toString();

    return urlObj.toString();
};

export const cloneObjectHelps = (val) => {
    return JSON.parse(JSON.stringify(val));
};
export const getType = (obj: any): 'Array' | 'String' | 'Number' | 'Boolean' | 'unknown' | any => {
    if (obj === null) {
        return 'null';
    } else if (Array.isArray(obj)) {
        return 'Array';
    } else if (typeof obj === 'string') {
        return 'String';
    } else if (typeof obj === 'number') {
        return 'Number';
    } else if (typeof obj === 'boolean') {
        return 'Boolean';
    } else if (typeof obj === 'object') {
        // Vous pouvez affiner le typage des objets ici en fonction de vos besoins
        if (obj.constructor) {
            return obj.constructor.name;
        } else {
            return 'Object';
        }
    } else {
        return 'unknown';
    }
};
export const isEmpty=(value: any): boolean => {
    return value === null || value === undefined || value === '';
}
