/** https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/d3-selection/d3-selection-tests.ts**/

import { Injectable, Injector } from '@angular/core';
import * as d3 from 'd3-selection';
import { SyosSection } from '../../models/syos-section';
import { SyosLevel } from '../../models/syos-level';
import { SyosSeat } from '../../models/syos-seat';
import { SyosVenue } from '../../models/syos-venue';
import { Cart } from '../../models/cart';
import { environment } from '../../../../../../environments';
import * as d3Sharp from 'd3-shape';
import * as d3Zoom from 'd3-zoom';
import { SeatMapService, TypeApi } from './seat-map.service';
import { InjectorInstance } from './seats-map.module';
import { SyosVenueDetails } from '../../models/syos-venue-details';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { SharedConfigService } from '../../services/shared-config.service';
import { ZoomInterface, ZoomService } from '../control-zoom/zoom.service';
import { select, selectAll } from 'd3-selection';
import { SyosSeatDetail } from '../../models/syos-seat-detail';

export interface SectionPolygonsToDrawWithSeatsInZoomIn {
    id: any;
    completed: boolean;
    colors: any[];
    outSide?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DrawSeatMapsService {
    constructor(private injector: Injector, public sharedConfigService: SharedConfigService) {
        console.log(this.sharedConfigService.config);
    }

    static d3;
    static bodySvg;
    static containerSvg: any;
    static zoom;
    static scaleFactor = 10;
    static seatSizeFactor = 0.4;
    static seatSpacingDefault = 0.1;
    static margin = { top: -5, right: -5, bottom: -5, left: -5 };
    static width = window.innerWidth - DrawSeatMapsService.margin.left - DrawSeatMapsService.margin.right;
    static height = window.innerHeight - 175 - DrawSeatMapsService.margin.top - DrawSeatMapsService.margin.bottom;
    static darwPolygon = false;
    static previousSection: any;
    static scaleInit: any;
    static constSeatMap = {
        SEAT: 'seat',
        ROW: 'row',
        SECTION: 'section',
        LEVEL: 'level',
        VENUE: 'venue'
    };
    static watchZoomPolygons = new BehaviorSubject(null);
    static watchZoom = new BehaviorSubject(d3Zoom.zoomIdentity);
    static listenEventsSeats = new BehaviorSubject(null);
    // @ts-ignore
    static listSectionPolygonsToDrawWithSeatsInZoomIn: SectionPolygonsToDrawWithSeatsInZoomIn[] = [];
    static maxToLoadInOnTime = 15;

    static priceLevelSelected = new BehaviorSubject(null);

    static isPolygonView = false;

    static setBackgroundVenue(url, width, height, tran) {
        /** './resources/images/icons/imperial_plansalle.svg'  **/
        //  console.log(url);
        DrawSeatMapsService.containerSvg.append('svg:image')
            .attr('id', 'svg-background-venue')
            .attr('href', url);

    }

    static createConstructor(d3js, zoomVal, width, height) {
        DrawSeatMapsService.d3 = d3js;
        DrawSeatMapsService.zoom = zoomVal;
        ZoomService.instance = zoomVal;

        d3.select('#chart').selectAll('*').remove();
        DrawSeatMapsService.width = width;
        DrawSeatMapsService.height = height;
        DrawSeatMapsService.bodySvg = d3.select('#chart')
            .append('svg')
            .attr('width', width)
            .attr('height', height);
        //.append('g')
        //.attr('transform', 'translate(' + margin.left + ',' + margin.right + ')')
        if (zoomVal) {
            // d3.select('#chart')
            //      .on('dblclick.zoom', null)
            //      .call(ZoomService.instance);
            ZoomService.bodySvg = DrawSeatMapsService.bodySvg;
            ZoomService.bodySvg.call(ZoomService.instance);


            // DrawSeatMapsService.bodySvg.call(ZoomService.instance);
            ZoomService.bodySvg.on('dblclick.zoom', null);
        }

        DrawSeatMapsService.bodySvg.selectAll('*').remove();


        DrawSeatMapsService.containerSvg = DrawSeatMapsService.bodySvg.append('g')
            .attr('id', 'svg-container')
            .attr('class', 'svg-container');

        // .attr('width', width)
        // .attr('height', height);

        // .attr('transform', function () {
        //  return 'translate(154.1397510816422,-45.23138129536272) scale(1.800003859587025)';
        // });
    }

    static setSeatSizeFactor(seatSizeFactorVal) {
        DrawSeatMapsService.seatSizeFactor = seatSizeFactorVal;
    }

    static getSvg() {
        return DrawSeatMapsService.containerSvg;
    }

    static getD3() {
        return DrawSeatMapsService.d3;
    }

    static getZoom() {
        return DrawSeatMapsService.zoom;
    }

    static getSeat(id) {
        return DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + id);
    }

    static getSection(id) {
        return DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SECTION + id);
    }

    static getRow(id) {
        return DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.ROW + id);
    }

    static drawSeat(svgRow, seatData, i, venue?, level?, section?, row?) {

        const svgSeat = DrawSeatMapsService.createGroup(svgRow, seatData, DrawSeatMapsService.constSeatMap.SEAT, seatData.id);
        if (row) {
            svgSeat.classed(`row${row}`, true);
        }
        if (section) {
            svgSeat.classed('seat-section-' + section, true);
        }

        svgSeat.append('circle')
            .attr('id', 'circle-seat' + seatData.id)
            .attr('class', 'circle-seat')
            .attr('r', DrawSeatMapsService.convertUnitWithScaleFactor(DrawSeatMapsService.seatSizeFactor))
            .style('text-anchor', 'middle')
            .style('fill', '#0d0d0d')
            .style('opacity', 0.5);
        svgSeat.append('text')
            .attr('transform', function() {
                return DrawSeatMapsService.getTranslate(0, 2);
            })
            .attr('id', 'circle-seat-text' + seatData.id)
            .attr('class', 'label-seat')
            .style('text-anchor', 'middle')
            .style('fill', 'black')
            .style('stroke', 'transparent')
            .style('font-size', DrawSeatMapsService.convertUnitWithScaleFactor(0.5) + 'px')
            .text(seatData.name);
        return svgSeat;
    }

    static drawSeatWithDataPrice(seatId: number, color: string, priceLevelId: number, syosSeatDetail: SyosSeatDetail, text?: string, divisor?: number) {
        const svgSeat = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + seatId);
        // console.log(svgSeat._groups[0][0].__data__)
        if (syosSeatDetail.invisible) {
            svgSeat.select('#circle-seat' + seatId)
                .style('fill', 'transparent')
                .style('stroke', 'transparent');
            svgSeat.select('#circle-seat-text' + seatId)
                .style('fill', 'transparent');
        } else if (syosSeatDetail.sellable) {
            svgSeat.classed("sellable", true);
            svgSeat.datum().priceLevelId = priceLevelId;
            svgSeat.datum().color = color;
            svgSeat.datum().reserve = false;
            svgSeat.datum().divisor = divisor;
            svgSeat.datum().note = syosSeatDetail.note;
            //console.log(divisor);
            svgSeat.style('cursor', 'pointer');
            if (text) {
                svgSeat.append('text')
                    .attr('class', 'label-seat')
                    .style('text-anchor', 'middle')
                    .style('fill', 'black')
                    .style('font-size', DrawSeatMapsService.convertUnitWithScaleFactor(0.3) + 'px')
                    .text(text);
            }
            if (color) {
                svgSeat.select('#circle-seat' + seatId)
                    .attr('class', 'circle-seat')
                    .attr('r', DrawSeatMapsService.convertUnitWithScaleFactor(DrawSeatMapsService.seatSizeFactor))
                    .style('text-anchor', 'middle')
                    .style('fill', color)
                    .style('opacity', 1)
                    .style('stroke', 'black')
                    .style('stroke-width', 0.2);
            }
        }
    }

    static hideIfInvisible(seatId: number, syosSeatDetail: SyosSeatDetail) {
        const svgSeat = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + seatId);
        // console.log(svgSeat._groups[0][0].__data__)
        if (syosSeatDetail.invisible) {
            svgSeat.select('#circle-seat' + seatId)
                .style('fill', 'transparent')
                .style('stroke', 'transparent');
            svgSeat.select('#circle-seat-text' + seatId)
                .style('fill', 'transparent');
        }
    }

    static getPriceLevelIdFromSeat(id) {
        const temp = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + id).datum();
        if (!temp || !temp.priceLevelId) {
            return null;
        }
        if (temp.reserve) {
            return null;
        }
        return temp.priceLevelId;
    }
    static getNote(id) {
        const temp = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + id).datum();
        if (!temp || !temp.note) {
            return null;
        }
        return temp.note;
    }

    static getDivisorFromSeat(id) {
        const temp = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + id).datum();
        if (!temp || !temp.divisor) {
            return 1;
        }

        return temp.divisor;
    }

    static drawRow(svgSection, rowData, sectionName, levelName) {
        const rowSvg = DrawSeatMapsService.createGroup(svgSection, rowData, DrawSeatMapsService.constSeatMap.ROW, rowData.id);
        // rowData.seats.sort(function (a, b) {
        //     b.x = parseFloat(b.x);
        //     a.x = parseFloat(a.x);
        //     return a.x - b.x;
        // });
        rowData.seats.forEach(function(seat, i) {

            seat.rowName = rowData.name;
            if (sectionName) {
                seat.sectionName = sectionName;
            }
            if (levelName) {
                seat.levelName = levelName;
            }
            const svgSeat = DrawSeatMapsService.drawSeat(rowSvg, seat, i);
            DrawSeatMapsService.createListenEvent(svgSeat);

        });
        return rowSvg;
    }


    static drawSection(svgLevel, sectionData: SyosSection, levelName) {
        sectionData.rotation = 0;
        sectionData.rotationX = 0;
        sectionData.rotationY = 0;
        sectionData.x = 0;
        sectionData.y = 0;
        const svgSection = DrawSeatMapsService.createGroup(svgLevel, sectionData, DrawSeatMapsService.constSeatMap.SECTION, sectionData.id);
        // svgSection.append('text')
        //     .style('font-size', convertUnitWithScaleFactor(0.3) + 'px')
        //     .text(sectionData.name);

        sectionData.rows.forEach((row) => {
            DrawSeatMapsService.drawRow(svgSection, row, sectionData.name, levelName);
        });

        return svgSection;
    }


    static drawLevel(levelData, svgVenue) {
        levelData.rotation = 0;
        levelData.rotationX = 0;
        levelData.rotationY = 0;
        levelData.x = 0;
        levelData.y = 0;
        const svglevel = DrawSeatMapsService.createGroup(svgVenue, levelData, DrawSeatMapsService.constSeatMap.LEVEL, levelData.id);
        levelData.sections.forEach((section) => {
            DrawSeatMapsService.drawSection(svglevel, section, levelData.name);
        });
        return svglevel;
    }


    static drawVenue(venueData) {
        d3.select('#venue' + venueData.id).remove();
        DrawSeatMapsService.createVenue(venueData);
        venueData.levels.forEach((level) => {
            DrawSeatMapsService.drawLevel(level, d3.select('#venue' + venueData.id));
        });
    }

    static createVenue(venueData) {
        const svgVenue = DrawSeatMapsService.createGroup(DrawSeatMapsService.containerSvg, venueData, DrawSeatMapsService.constSeatMap.VENUE, venueData.id);
        let imageUrl = (venueData.imageUrl) ? environment.urlApi + venueData.imageUrl.substring(1, venueData.imageUrl.length) : '';

        if (environment.systemValues === 'mta' && window.location.hostname.search('capitole') !== -1) {
            imageUrl = imageUrl.replace('https://billetteriealacarte.com/api', 'https://capitole.boutiquecomediha.com/api');
        }

        svgVenue
            .append('g')
            .attr('id', 'group-background-venue')
            .attr('transform', function() {
                if (venueData.scaleImage) {
                    return venueData.scaleImage;
                }

                return 'translate(0,0) scale(1)';
            })
            .append('svg:image')
            .attr('id', 'svg-background-venue')
            .attr('href', imageUrl);


    }


    static addBackground(svg, url: string) {
        svg.append('defs')
            .append('pattern')
            .attr('id', 'bg')
            .attr('width', '100%')
            .attr('height', '100%')
            .append('image')
            .attr('xlink:href', url)
            .attr('width', '100%')
            .attr('height', '100%');

        // svg.append('rect')
        //     .attr('transform', 'translate(0, 100)')
        //     .attr('width', '100%')
        //     .attr('height', '100%')
        //     .attr('stroke', ' #e6e6e6')
        //     .attr('fill', 'url(#bg)');

        return svg;
    }

    /** ploygons **/

    static drawSectionPolygons(svg, section: SyosSection, level: SyosLevel) {

        section.polygons.forEach((polygon, index) => {
            if (polygon) {
                svg.append('svg:path')
                    .data([
                        {
                            'levelCor': { 'x': level.x, 'y': level.y },
                            'sectionCor': { id: section.id, 'x': section.x, 'y': section.y }

                        }])
                    .attr('class', 'sectionPolygon')
                    .attr('visibility', 'visible')
                    .attr('id', 'sectionPolygon-' + section.id + '-' + index)
                    .attr('points', polygon)
                    .attr('stroke', 'green')
                    .style('fill', 'green')

                    .attr('stroke-width', 2)
                    .attr('transform', DrawSeatMapsService.getTransformConvert(section))
                    .on('click', DrawSeatMapsService.zoomSection);
                if (index + 1 === section.polygons.length) {
                    //console.log(section.id + '-' + index);
                }
            }
        });
        // for (const p = 0; p < section.polygons.length; p++) {
        //
        //
        // }
    }

    static zoomSection(d) {

        // if (DrawSeatMapsService.previousSection) {
        //     DrawSeatMapsService.previousSection.style('stroke', 'green')
        //         .style('fill', 'green')
        //         .classed('selected', false);
        // }
        //
        // DrawSeatMapsService.previousSection = d3.select(this);
        //
        // DrawSeatMapsService.previousSection.style('stroke', 'yellow')
        //     .style('fill', 'yellow')
        //     .classed('selected', true);
        //
        // const bbox = d3.select(this).node().getBBox();
        // const rectAttr = {
        //     x: bbox.x,
        //     y: bbox.y,
        //     width: bbox.width,
        //     height: bbox.height
        // };
        // DrawSeatMapsService.containerSvg.selectAll('#section' + d.sectionCor.id);
        // const xCenter = rectAttr.x + rectAttr.width / 2 + d.levelCor.x + d.sectionCor.x;
        // const yCenter = rectAttr.y + rectAttr.height / 2 + d.levelCor.y + d.sectionCor.y;
        // const scale = 3; // Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height)));
        // const translate = [DrawSeatMapsService.width / 2 - scale * xCenter, DrawSeatMapsService.height / 2 - scale * yCenter];
        // DrawSeatMapsService.containerSvg.transition()
        //     .duration(750)
        //     .call(DrawSeatMapsService.zoom.transform, d3.zoomIdentity.translate(translate[0], translate[1]).scale(scale));
    }

    // static createPolygonSection(section: SyosSection) {
    //     const polygon_arr: Feature<Polygon>[] = [];
    //     if (!section || !section.rows) {
    //         return polygon_arr;
    //     }
    //
    //     section.rows.forEach(function (row, key) {
    //         // row.polygons = createRowPolygons(row)
    //         polygon_arr[key] = {
    //             geometry: <Polygon> {
    //                 coordinates: DrawSeatMapsService.createRowPolygons(row),
    //                 type: 'Polygon'
    //                 // type: 'MultiPolygon'
    //             },
    //             properties: {
    //                 'fill': '#6BC65F',
    //                 'stroke': '#6BC65F',
    //                 'stroke-width': 5
    //             },
    //             type: 'Feature'
    //         };
    //
    //     });
    //     const united: Feature<Polygon | MultiPolygon>[] = [];
    //     let points = [];
    //     try {
    //         if (polygon_arr.length >= 0) {
    //             united[0] = polygon_arr[0];
    //             polygon_arr.forEach((value, index) => {
    //                 united[0] = union(polygon_arr[0], polygon_arr[index]);
    //             });
    //             // for (let m = 1; m < polygon_arr.length; m++) {
    //             //     united[0] = union(polygon_arr[0], polygon_arr[m]);
    //             //     // console.log(united[0])
    //             // }
    //             const combined = combine(featureCollection(united));
    //             if (combined) {
    //                 points = combined.geometry.coordinates;
    //             }
    //
    //             // if (combined && combined.features[0] && combined.features[0].geometry && combined.features[0].geometry.coordinates) {
    //             //     points = combined.features[0].geometry.coordinates;
    //             // }
    //         }
    //     } catch (e) {
    //         console.log(section);
    //         console.log(e);
    //     }
    //
    //
    //     return points;
    // }
    //
    // static createRowPolygons(row: SyosRow) {
    //     const polygon_arr: Feature<Polygon>[] = [];
    //     if (!row || !row.seats) {
    //         return polygon_arr;
    //     }
    //     row.seats.forEach(function (seat, key) {
    //         const seatSpace = row.seatSpacing || DrawSeatMapsService.seatSpacingDefault;
    //
    //         const coordinates = DrawSeatMapsService.getCoordinatesSeat(seat, seatSpace);
    //         polygon_arr[key] = {
    //             geometry: {
    //                 coordinates: coordinates,
    //                 type: 'Polygon'
    //             },
    //             properties: {
    //                 'fill': '#6BC65F',
    //                 'stroke': '#6BC65F',
    //                 'stroke-width': 5
    //             },
    //             type: 'Feature'
    //         };
    //     });
    //     const united: Feature<Polygon | MultiPolygon>[] = [];
    //     let points = [];
    //     const m = 1;
    //
    //
    //     try {
    //         if (polygon_arr.length >= 2) {
    //             united[0] = polygon_arr[0];
    //             polygon_arr.forEach((value, index) => {
    //                 try {
    //                     united[0] = union(polygon_arr[0], polygon_arr[index]);
    //                 } catch (ee) {
    //                     console.log('polygon_arr #############', polygon_arr[index]);
    //                     console.log(row);
    //                     console.log(ee);
    //                 }
    //             });
    //
    //             // for (let m = 0; m < polygon_arr.length; m++) {
    //             //     try {
    //             //         united[0] = union(polygon_arr[0], polygon_arr[m]);
    //             //     } catch (ee) {
    //             //         console.log('polygon_arr #############', polygon_arr[m]);
    //             //         console.log(row);
    //             //         console.log(ee);
    //             //     }
    //             //
    //             // }
    //             const temp = featureCollection(united);
    //             const combined = combine(temp);
    //             if (combined) {
    //                 points = combined.geometry.coordinates;
    //             }
    //             // if (combined && combined.features[0] && combined.features[0].geometry && combined.features[0].geometry.coordinates) {
    //             //     points = combined.features[0].geometry.coordinates;
    //             // }
    //         }
    //     } catch (e) {
    //         console.log('mmmmm', m);
    //         console.log('polygon_arr #############', polygon_arr);
    //         console.log(row);
    //         console.log(e);
    //     }
    //
    //
    //     return points;
    // }


    static convertUnitWithScaleFactor(val: number): number {
        if (!val) {
            return 0;
        }
        return val * DrawSeatMapsService.scaleFactor;
    }

    static converScaleFactorToUnit(val: number): number {
        if (!val) {
            return 0;
        }
        return val / DrawSeatMapsService.scaleFactor;
    }

    static getCoordinatesSeat(seat: SyosSeat, seatSpace: number) {
        const coordinates = [];
        coordinates[0] = [];
        if (!seat) {
            return coordinates;
        }
        const lengthSquare = (2 * seatSpace) + (2 * DrawSeatMapsService.seatSizeFactor);
        // lengthSquare = convertUnitWithScaleFactor(lengthSquare);
        let rotation = seat.rotation || 0;
        rotation = parseFloat(String(rotation));

        coordinates[0][0] = DrawSeatMapsService.getPointSquareX_Y(seat.x, seat.y, 0, 0);
        coordinates[0][1] = DrawSeatMapsService.getPointSquareX_Y(seat.x, seat.y, lengthSquare, rotation);
        coordinates[0][2] = DrawSeatMapsService.getPointSquareX_Y(seat.x, seat.y, Math.sqrt(2 * Math.pow(lengthSquare, 2)), rotation + 45);
        coordinates[0][3] = DrawSeatMapsService.getPointSquareX_Y(seat.x, seat.y, lengthSquare, rotation + 90);
        coordinates[0][4] = coordinates[0][0];

        return coordinates;
    }

    static getPointSquareX_Y(valX, valY, r, rotation) {
        const temp = DrawSeatMapsService.getPointSquareX_YWithoutScaleFactor(valX, valY, r, rotation);
        const xy = [DrawSeatMapsService.convertUnitWithScaleFactor(temp[0]), DrawSeatMapsService.convertUnitWithScaleFactor(temp[1])];
        return xy;
    }

    static getPointSquareX_YWithoutScaleFactor(valX, valY, r, rotation) {
        valX = parseFloat(valX);
        valY = parseFloat(valY);
        r = parseFloat(r);
        rotation = parseFloat(rotation);
        if (rotation > 360) {
            rotation = rotation - 360;
        }

        const rad = rotation * (Math.PI / 180);
        // console.log(rad);
        const x = valX + (Math.cos(rad) * r);
        const y = valY + (Math.sin(rad) * r);

        const xy = [x, y];
        return xy;
    }

    static createGroup(svgObj, data: SyosVenue | SyosLevel | SyosSection | SyosSeat, name, id) {
        try {
            // console.log(data);
            // d3.select('#venue' + data.id).remove();
            svgObj.append('g')
                .attr('class', name)
                .attr('id', name + data.id)
                .attr('transform', function() {
                    return DrawSeatMapsService.getTransformConvert(data, name === 'seat');
                });
            const temp = DrawSeatMapsService.containerSvg.select('#' + name + data.id);
            //  console.log(data);
            if (name === 'seat' || name === 'row') {
                temp.data([{
                    'data': data
                }]);
            }


            return temp;
        } catch (e) {
            //console.log(e);
        }

    }

    static drawGroup(level1, level2, level3, levelId1, levelId2, data) {
        const svgLevel1 = DrawSeatMapsService.getGroup(level1, levelId1);
        const svgLevel2 = DrawSeatMapsService.getGroup(level2, levelId2);
        svgLevel2.selectAll('.' + level3).remove();
        DrawSeatMapsService.drawRow(svgLevel1, data, '', '');
    }

    static deleteGroup(name, id) {
        DrawSeatMapsService.containerSvg.selectAll('#' + name + id).remove();
    }

    static getGroup(name, id) {
        return DrawSeatMapsService.containerSvg.select('#' + name + id);
    }

    static setDataGroup(data, name, id) {
        const svgGroup = DrawSeatMapsService.getGroup(name, id);
        svgGroup.datum().data = data;
    }

    static setDataVerionGroup(version, name, id) {
        const svgGroup = DrawSeatMapsService.getGroup(name, id);
        svgGroup.datum().data.version = version;
    }

    static getRotate(r: number, x: number, y: number) {

        if (!r) {
            r = 0;
        }
        if (!x) {
            x = 0;
        }
        if (!y) {
            y = 0;
        }

        return `rotate(${r},${x}, ${y})`;
    }

    static getTranslate(x, y) {

        if (!x) {
            x = 0;
        }
        if (!y) {
            y = 0;
        }
        return `translate( ${x} , ${y} )`;
    }

    static getTransform(x?: any, y?: any, r?: any, rotationX?: any, rotationY?: any) {
        return DrawSeatMapsService.getTranslate(x, y) + ' ' + DrawSeatMapsService.getRotate(r, rotationX, rotationY);
    }

    static getTransformConvert(data, isSeat?: any) {
        const x = DrawSeatMapsService.convertUnitWithScaleFactor(data.x);
        const y = DrawSeatMapsService.convertUnitWithScaleFactor(data.y);
        let rotation = 0;
        let rotationX = 0;
        let rotationY = 0;
        if (!isSeat) {
            rotation = data.rotation;
            rotationX = DrawSeatMapsService.convertUnitWithScaleFactor(data.rotationX);
            rotationY = DrawSeatMapsService.convertUnitWithScaleFactor(data.rotationY);
        }

        return DrawSeatMapsService.getTransform(x, y, rotation, rotationX, rotationY);
    }

    static getValidSeatsOnSale(SeatContainer, qty, avoidSingle = true, algorithmEnable = false): SyosSeat[] {


        let seatValidToReserve: SyosSeat[] = [];
        let row = null;
        let i = 0;
        let typeTable = null;
        let seats = [];
        /** mode seat-map avec seats **/
        let temp = DrawSeatMapsService.getSvg().select('#row' + SeatContainer.rowId);

        if (!temp.empty() && temp.datum() && temp.datum().data) {
            row = temp.datum().data;
            typeTable = row.type;
            seats = row.seats;
        } else {
            /** mode seat-map polygone avec seats **/
            temp = DrawSeatMapsService.getSvg().selectAll('.row' + SeatContainer.rowId);
            temp.each(function(d) {
                //console.log(d)
                seats.push(d.data);
            });
            if (seats.length === 0) {
                return seatValidToReserve;
            }

        }


        let indexStart = DrawSeatMapsService.containsSeat(seats, SeatContainer.id);

        if (indexStart === -1 || !seats) {
            return seatValidToReserve;
        }
        if (!DrawSeatMapsService.getPriceLevelIdFromSeat(SeatContainer.id)) {
            return seatValidToReserve;
        }
        const idPl = DrawSeatMapsService.getPriceLevelIdFromSeat(SeatContainer.id);
        const divisor = DrawSeatMapsService.getDivisorFromSeat(SeatContainer.id);
        const selectionDivisor = divisor > 1;

        let seatTemp: SyosSeat[] = [];
        for (let k = indexStart; k >= 0; k--) {
            const ss = seats[k];
            if (ss && DrawSeatMapsService.getPriceLevelIdFromSeat(ss.id) === idPl) {
                seatTemp.push(ss);
            } else {
                break;
            }
        }
        for (let f = indexStart + 1; f < seats.length; f++) {
            const ss = seats[f];
            if (ss && DrawSeatMapsService.getPriceLevelIdFromSeat(ss.id) === idPl) {
                seatTemp.push(ss);
            } else {
                break;
            }
        }

        seatTemp = seatTemp.sort((a, b) => {
            return a.order - b.order;
        });
        //console.log(seatTemp);
        seats = seatTemp;
        if (seatTemp.length <= 2) {
            indexStart = 0;
        }

        //console.log(seats);
        indexStart = DrawSeatMapsService.containsSeat(seats, SeatContainer.id);

        let lastKey = -1;
        let startKey = -1;
        const priceLevelId = DrawSeatMapsService.getPriceLevelIdFromSeat(SeatContainer.id);
        while (i < seats.length) {
            seatValidToReserve = [];


            let nextSeat = true;
            let j = 0;
            startKey = indexStart;
            let count = indexStart;

            while (j < seats.length) {
                if (count < seats.length && seatValidToReserve.length < qty) {
                    const seat = seats[count];
                    if (!DrawSeatMapsService.samePriceLevelId(seat.id, priceLevelId)) {
                        j = seats.length;
                    } else {
                        if (seatValidToReserve.length < qty && nextSeat) {
                            seatValidToReserve.push(seat);
                            lastKey = count;
                            const tempNextSeat = seats[count + 1];
                            if (tempNextSeat) {
                                const hasNextSeat = tempNextSeat && DrawSeatMapsService.samePriceLevelId(tempNextSeat.id, priceLevelId);
                                if (!(hasNextSeat)) {
                                    nextSeat = false;
                                }
                            } else {
                                nextSeat = false;
                            }
                        }
                        count++;
                        if (count === seats.length && typeTable === 'TABLE') {
                            count = 0;
                            nextSeat = true;

                        }
                    }
                }
                j++;
            }
            // console.log(seatValidToReserve);
            let singleSeat = true;
            const notReserve = seats.filter(se => DrawSeatMapsService.getPriceLevelIdFromSeat(se.id) !== null);
            if (avoidSingle && notReserve.length > 1) {
                singleSeat = DrawSeatMapsService.hasSingleSeat(seats, notReserve, seatValidToReserve, priceLevelId, startKey, lastKey, typeTable, algorithmEnable);
                //console.log(j, singleSeat);
            }
            i++;
            if (seatValidToReserve.length === qty && (singleSeat || notReserve.length === seatValidToReserve.length)) {
                i = seats.length;
            } else {
                seatValidToReserve = [];
                if (indexStart > 0) {
                    indexStart--;
                } else {
                    i = seats.length;
                    if (typeTable === 'TABLE' && indexStart === 0) {
                        indexStart = seats.length - 1;
                    } else {
                        i = seats.length;
                    }

                }

            }


        }
        //console.log(seatValidToReserve);
        if (seatValidToReserve.length !== qty || DrawSeatMapsService.containsSeat(seatValidToReserve, SeatContainer.id) === -1) {
            return [];
        }
        // console.log(seatValidToReserve);
        if (divisor > 1 && seatValidToReserve.length > 0 && (seatValidToReserve.length % divisor) > 0) {
            return [];
        }
        return seatValidToReserve;
    }

    static containsSeat(seats, seatId) {
        let temp = -1;
        for (let i = 0; i < seats.length; i++) {
            if (seats[i].id === seatId) {
                return temp = i;
            }
        }
        return temp;
    }

    static hasNextTowOrEmptySeat(seats, seatValidToReserve, priceLevelId, lastKey, type) {
        if (!seats) {
            return false;
        }
        let next1 = lastKey + 1;
        let next2 = lastKey + 2;

        if (type === 'TABLE') {
            if (next1 === seats.length) {
                next1 = 0;
            }
            if (next2 === seats.length) {
                next2 = 0;
            }
            if (next2 === seats.length + 1) {
                next2 = 1;
            }
        }

        const tempNextSeat = seats[next1];
        let hasNextSeat = null;
        let hasNextSeat2 = null;
        if (tempNextSeat) {
            if (tempNextSeat.reserve) {
                return true;
            }
            hasNextSeat = tempNextSeat && DrawSeatMapsService.samePriceLevelId(tempNextSeat.id, priceLevelId);
            let tempNextSeat2 = seats[next2];
            if (
                tempNextSeat2 &&
                seatValidToReserve.find((val) => val.id === tempNextSeat2.id)
            ) {
                tempNextSeat2 = null;
            }

            if (tempNextSeat2) {
                hasNextSeat2 = tempNextSeat && DrawSeatMapsService.samePriceLevelId(tempNextSeat2.id, priceLevelId);
            }
        }

        return hasNextSeat === hasNextSeat2;
    }

    static samePriceLevelId(seatId, priceLevelId) {
        // console.log(DrawSeatMapsService.getPriceLevelIdFromSeat(seatId), priceLevelId);
        return DrawSeatMapsService.getPriceLevelIdFromSeat(seatId) === priceLevelId;
    }

    static hasSingleSeat(seats, seatStayToReserve, seatValidToReserve, priceLevelId, startKey, lastKey, type, algorithmEnable) {
        //  console.log(DrawSeatMapsService.hasNextTowOrEmptySeat(seats, priceLevelId, lastKey, type) );
        // console.log(DrawSeatMapsService.hasPreviousTowOrEmptySeat1(seats, priceLevelId, startKey, type) );
        const seatdta = [];
        seats.forEach(se => {
            const s = DrawSeatMapsService.containerSvg.select('#' + DrawSeatMapsService.constSeatMap.SEAT + se.id).datum();
            if (s.reserve === undefined) {
                se.reserve = true;
            }

            seatdta.push(se);
        });
        //console.log(seatdta);
        if (algorithmEnable &&
            seatStayToReserve.length > 2 &&
            seatStayToReserve.length - seatValidToReserve.length === 1 &&
            ((seats[startKey - 1] && !seats[startKey - 1].reserve) ||
                (seats[lastKey + 1] && !seats[lastKey + 1].reserve))
        ) {
            return true;
        }


        return DrawSeatMapsService.hasNextTowOrEmptySeat(seatdta, seatValidToReserve, priceLevelId, lastKey, type)
            && DrawSeatMapsService.hasPreviousTowOrEmptySeat1(seatdta, seatValidToReserve, priceLevelId, startKey, type);

    }

    static hasPreviousTowOrEmptySeat1(seats, seatValidToReserve, priceLevelId, startKey, type) {
        if (!seats) {
            return false;
        }

        let previous1 = startKey - 1;
        let previous2 = startKey - 2;

        if (type === 'TABLE') {
            if (previous1 === -1) {
                previous1 = seats.length - 1;
            }
            if (previous2 === -1) {
                previous2 = seats.length - 1;
            }
            if (previous2 === -2) {
                previous2 = seats.length - 2;
            }

        } else {
            if (startKey === 0) {
                return true;
            }
        }

        const tempNextSeat = seats[previous1];

        let hasNextSeat = null;
        let hasNextSeat2 = null;
        if (tempNextSeat) {
            if (tempNextSeat.reserve) {
                return true;
            }
            hasNextSeat = tempNextSeat && DrawSeatMapsService.samePriceLevelId(tempNextSeat.id, priceLevelId);
            let tempNextSeat2 = seats[previous2];
            if (
                tempNextSeat2 &&
                seatValidToReserve.find((val) => val.id === tempNextSeat2.id)
            ) {
                tempNextSeat2 = null;
            }

            if (tempNextSeat2) {
                hasNextSeat2 = tempNextSeat && DrawSeatMapsService.samePriceLevelId(tempNextSeat2.id, priceLevelId);
            }

        }
        return hasNextSeat === hasNextSeat2;
    }


    static addReserveTag(cart: Cart, id, isSub) {

        cart.cartElements.forEach(value => {

            if (id && value.id === parseInt(id, 10)) {
                value.prices.forEach(value1 => {
                    value1.cartDetailToken.forEach(hold => {
                        if (hold.seat && hold.seat.id) {
                            DrawSeatMapsService.drawReserveSeat(hold.seat.id);

                        }
                    });
                });
            }

        });

    }

    static drawReserveSeat(id) {
        const temp = DrawSeatMapsService.getSeat(id);
        //console.log(temp);
        if (!temp.empty()) {
            temp.append('svg:image')
                .attr('id', 'activeSeat' + id)
                .attr('class', 'active-image-seat')
                .attr('x', -2)
                .attr('y', -3)
                .attr('width', 5)
                .attr('height', 5)
                .attr('xlink:href', './assets/resources/svg/icon_done_black.svg')
                .style('fill', 'greenyellow');
            temp.datum().reserve = true;

            const circle = temp.select('circle');
            circle.style('fill', 'greenyellow');
            const text = temp.select('text');
            text.style('fill', 'transparent');
            const color = temp.datum().color;
            // if (color) {
            //     circle.style('fill', 'greenyellow');
            // }
        }
    }

    static drawVenuePolygon(venueData: SyosVenueDetails, eventId: number) {
        DrawSeatMapsService.createVenue(venueData);
        const seatMapService = InjectorInstance.get<SeatMapService>(SeatMapService);
        venueData.levels.forEach((level) => {
            level.sections.forEach(section => {
                section.colors = [];
                try {
                    section.priceLevels.forEach(pl => {
                        // console.log(venueData.priceLevels, pl);
                        const pLevel = seatMapService.getPriceLevel(venueData.priceLevels, pl);
                        //console.log(pLevel);
                        if (pLevel) {
                            section.colors.push(pLevel.color);
                        } else {
                            section.colors.push('white');
                        }

                    });
                } catch (e) {
                    //console.log(e);
                }

                DrawSeatMapsService.drawPolygonOnly(section, venueData, eventId);
            });
        });
    }

    static drawPolygonOnly(section: SyosSection, venueData: SyosVenueDetails, eventId: number) {
        const namePolygon = 'sectionPolygon';
        const sectionId = section.id;
        const points = section.polygons || [];
        const venueId = venueData.id;
        let active = d3.select(null);
        const width = DrawSeatMapsService.width;
        const height = DrawSeatMapsService.height;
        d3.select('#' + namePolygon + sectionId).remove();
        const svgVenue = d3.select('#venue' + venueId);
        const lineGenerator = d3Sharp.line();
        lineGenerator.curve(d3Sharp.curveLinearClosed);
        // @ts-ignore
        const temp = lineGenerator(points.slice(0, points.length));
        let color = 'rgba(181,181,181,0.13)';
        if (section.colors && section.colors.length > 0) {
            color = section.colors[0];
        }
        const a = svgVenue.append('path')
            .attr('id', namePolygon + sectionId)
            .attr('class', namePolygon)
            .attr(namePolygon, sectionId)
            .style('stroke', 'rgba(238, 238, 238, 1)')
            .style('stroke-width', '1')
            .style('fill', color)
            .attr('d', temp)
            .on('click', function(d) {
               if(DrawSeatMapsService.isPolygonView){


                   active = d3.select(this).classed('active', true);

                   const element = document.querySelector('#chart');
                   // @ts-ignore
                   const bbox2 = element.getBoundingClientRect();

                   const vw = bbox2.width;	// container width
                   const vh = bbox2.height;	// container height
                   const bbox = this.getBBox();
                   const rectAttr = {
                       x: Math.floor(bbox.x + bbox.width / 2.0),
                       y: Math.floor(bbox.y + bbox.height / 2.0),
                       width: bbox.width,
                       height: bbox.height
                   };
                   console.log(rectAttr);
                   const scale = Math.max(1, Math.min(8, 0.9 / Math.max(rectAttr.width / vw, rectAttr.height / vh)));
                   const translate = [vw / 2 - scale * rectAttr.x, vh / 2 - scale * rectAttr.y];
                   DrawSeatMapsService.isPolygonView = false;

                   ZoomService.watchZoom.next(<ZoomInterface>{
                       zoomIdentity: DrawSeatMapsService.drawPolygonWithSeats(d3Zoom.zoomIdentity.translate(translate[0], translate[1]).scale(scale)),
                       action: 'm'
                   });

               }

                // ZoomService.bodySvg.transition()
                //     .duration(1750)
                //     // .call(zoom.translate(translate).scale(scale).event); // not in d3 v4
                //     .call(ZoomService.instance.transform, DrawSeatMapsService.drawPolygonWithSeats(d3Zoom.zoomIdentity.translate(translate[0], translate[1]).scale(scale))); //

            });
        if (color) {
            a.attr('data-color', color)
                .data([
                    {
                        'colors': section.colors
                    }
                ]);
        }


        function reset() {
            active.classed('active', false);
            active = d3.select(null);
            DrawSeatMapsService.removeAllSeatsReset();
            //console.log('reset =======================> ');

            ZoomService.watchZoom.next(<ZoomInterface>{
                zoomIdentity: DrawSeatMapsService.hideAllSeats(ZoomService.scaleInit),
                action: 'm'
            });
            // DrawSeatMapsService.bodySvg.transition()
            //     .duration(1750)
            //     // .call( zoom.transform, d3.zoomIdentity.translate(0, 0).scale(1) ); // not in d3 v4
            //     .call(DrawSeatMapsService.zoom.transform, DrawSeatMapsService.hideAllSeats(DrawSeatMapsService.scaleInit)); // updated for d3 v4
        }
    }

    static removeAllSeatsReset() {
        const seatMapService = InjectorInstance.get<SeatMapService>(SeatMapService);
        if (seatMapService.smallSeatsMap) {
            return;
        }
        selectAll('.seat').each(function() {
            DrawSeatMapsService.createListenEvent(select(this));
        });
        selectAll('.seat').remove();
        //DrawSeatMapsService.createListenEvent()
        if (DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.length === 0) {
            return;
        }

        /** todo  **/

        DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.forEach(value => {
            if (!value) {
                return;
            }
            d3.select('#sectionPolygon' + value.id)
                .style('stroke-width', 1);

            if (!value.colors || value.colors.length === 0) {
                return;
            }
            const color = value.colors[0];
            d3.select('#sectionPolygon' + value.id)
                .style('fill', color);


        });

        // @ts-ignore
        DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn = [];

    }


    static drawPolygonWithSeats(zoom: any) {
        DrawSeatMapsService.removeAllSeatsReset();
        DrawSeatMapsService.watchZoomPolygons.next('drawPolygonWithSeats');
        //console.log('drawPolygonWithSeats');
        // DrawSeatMapsService.watchZoom.next(zoom);
        return zoom;
    }

    static hideAllSeats(zoom: any) {
        //console.log('hideAllSeats');
        DrawSeatMapsService.watchZoomPolygons.next(null);
        // DrawSeatMapsService.watchZoom.next(zoom);

        return zoom;
    }

    static getRectPoint(points: any[]): any[][] {
        if (!points) {
            return;
        }
        const paddign = 0;

        //const p = [[50, 330], [75, 200], [280, 75], [300, 75], [475, 300], [600, 200]];
        let xs = Number.MAX_VALUE;
        let xb = Number.NEGATIVE_INFINITY;
        let ys = Number.MAX_VALUE;
        let yb = Number.NEGATIVE_INFINITY;
        // console.log(Number.NEGATIVE_INFINITY)

        points.forEach(value => {
            let px: any = value[0];
            let py: any = value[1];
            px = parseFloat(px);
            py = parseFloat(py);
            if (xs >= px) {
                xs = px;
            }
            if (xb <= px) {
                xb = px;
            }
            if (ys >= py) {
                ys = py;
            }
            if (yb <= py) {
                yb = py;
            }

        });
        return [[xs - paddign, ys - paddign],
            [xs - paddign, yb + paddign],
            [xb + paddign, yb + paddign],
            [xb + paddign, ys - paddign]];

    }

    static getPriceForSeatBySectionId(eventId, isSubscription, section: SyosSection, venueData: SyosVenueDetails, httpParams = new HttpParams) {
        const t = 10 * 60 * 1000;
        /* (m * s * ms) */

        const seatMapService = InjectorInstance.get<SeatMapService>(SeatMapService);
        httpParams = httpParams.set('cache', String(t));
        seatMapService.getPriceForSeatBySectionId(eventId, section.id, isSubscription, httpParams)
            .subscribe(syosSeatDetails => {
                //  console.log(syosSeatDetails);
                syosSeatDetails.forEach((dPrice) => {

                    const pLevel = seatMapService.getPriceLevel(venueData.priceLevels, dPrice.priceLevelId);
                    // console.log('%%%%%%%%%%%', pLevel);
                    //   const sStatus = this.seatMapService.getSeatStatus(this.venue.seatStatus, dPrice.seatStatusId);
                    let divisor;
                    pLevel?.prices.forEach(p => {
                            if (!divisor) {
                                divisor = p.divisor;
                            } else if (divisor !== p.divisor) {
                                divisor = 1;
                            }
                        }
                    );

                    //console.log(pLevel, dPrice, divisor);
                    if (pLevel) {
                        DrawSeatMapsService.drawSeatWithDataPrice(dPrice.seatId, pLevel.color, pLevel.id, dPrice, null, divisor);
                    } else {
                        DrawSeatMapsService.hideIfInvisible(dPrice.seatId, dPrice);
                    }
                });
            });

    }

    static getPolygonShowInMap(venueData: SyosVenueDetails) {
        const listSection = [];
        let nbSeat = 0;
        const parentElement = document.querySelector('#chart');
        venueData?.levels?.forEach((level) => {
            level.sections.forEach(se => {
                //const childElement = document.querySelector('#sectionPolygon' + se.id);
                const childElement = select('#sectionPolygon' + se.id);
                // console.log(childElement)
                const temp = DrawSeatMapsService.inside(parentElement, childElement.node());
                if (temp.length > 0) {
                    if (!listSection.find(value => value.id === se.id)) {
                        nbSeat = nbSeat + se.numberOfSeats;
                        listSection.push(se.id);
                    }
                }
            });
        });
        const seatMapService = InjectorInstance.get<SeatMapService>(SeatMapService);
        console.log(nbSeat);
        if (nbSeat < seatMapService.MIN_SHOW_SEATS) {
            DrawSeatMapsService.isPolygonView = false;
            listSection.forEach(id => {
                const childElement = select('#sectionPolygon' + id);
                const data = <[{ colors: any[] }]>childElement.data();
                if (!DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.find(value => value.id === id)) {
                    DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.push(<SectionPolygonsToDrawWithSeatsInZoomIn>
                        { id: id, completed: false, colors: data[0].colors, outSide: false });
                }
            });
            DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn =
                DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.filter(value => listSection.filter(value1 => value.id === value1.id));
        } else {
            DrawSeatMapsService.isPolygonView = true;
        }
        return {
            listSection: listSection, nbSeat: nbSeat
        };
    }

    static drawSeatsInPolygonZoomOut(svgVenue, venueData: SyosVenueDetails, eventId, isSubscription, httpParams) {

        // const parentElement = document.querySelector('#chart');
        // venueData.levels.forEach((level) => {
        //     level.sections.forEach(se => {
        //         const childElement = document.querySelector('#sectionPolygon' + se.id);
        //         const a = select('#sectionPolygon' + se.id);
        //         //console.log(childElement,a.node())
        //         const temp = DrawSeatMapsService.inside(parentElement, childElement);
        //         if (temp.length > 0) {
        //             if (!DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.find(value => value.id === se.id)) {
        //                 DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.push(<SectionPolygonsToDrawWithSeatsInZoomIn>
        //                     { id: se.id, completed: false, colors: se.colors, outSide: false });
        //             }
        //         } else {
        //             const tt = DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.find(value => value.id === se.id);
        //
        //             if (tt && !tt.outSide) {
        //                 tt.outSide = true;
        //             }
        //         }
        //     });
        // });
        //console.log(DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn);

        // if (DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.length > DrawSeatMapsService.maxToLoadInOnTime) {
        //     //console.log(DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.length);
        //     return;
        // }
        DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn
            .forEach(value => {
                console.log(value);
                if (!value.completed) {
                    value.completed = true;
                    d3.select('#sectionPolygon' + value.id)
                        .attr('class', 'completed')
                        .style('fill', 'transparent')
                        .style('stroke-width', 0);

                    const seatMapService = InjectorInstance.get<SeatMapService>(SeatMapService);
                    seatMapService.getSection(value.id, TypeApi.FULL).subscribe(sec => {
                        // console.log(value)

                        sec.rows.forEach(row => {
                            row.seats.forEach(seat => {
                                const svgSeat = DrawSeatMapsService.drawSeat(svgVenue, seat, '', venueData.id, sec.levelId, sec.id, row.id);
                                DrawSeatMapsService.createListenEvent(svgSeat);
                            });
                        });

                        DrawSeatMapsService.getPriceForSeatBySectionId(eventId, isSubscription, <SyosSection>{ id: value.id }, venueData, httpParams);
                    });
                }
                if (value.outSide) {
                    selectAll('.seat-section-' + value.id).remove();
                }

            });
        DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn =
            DrawSeatMapsService.listSectionPolygonsToDrawWithSeatsInZoomIn.filter(value => !value.outSide);
        if (DrawSeatMapsService.isPolygonView) {
            DrawSeatMapsService.removeAllSeatsReset();
        }


    }

    static createListenEvent(svgSeats) {
        const _platform = InjectorInstance.get<Platform>(Platform);
        const isMobil = _platform.ANDROID || _platform.IOS;
        if (isMobil) {
            svgSeats
                .on('touchstart', function(event, d) {
                    // console.log('touchstart', d);
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();
                    DrawSeatMapsService.listenEventsSeats.next({ event: 'click', data: data[0], listener: event });
                })
                .on('touchmove', function(event, d) {
                    //console.log('touchmove', d);
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();
                    DrawSeatMapsService.listenEventsSeats.next({ event: 'mouseover', data: data[0], listener: event });
                })
                .on('touchcancel', function(event, d) {
                    // console.log('touchcancel', d);
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();
                    DrawSeatMapsService.listenEventsSeats.next({ event: 'mouseout', data: data[0], listener: event });
                });

        } else {
            svgSeats
                .on('click', function(event, d) {
                    //console.log('click', d);
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();
                    DrawSeatMapsService.listenEventsSeats.next({ event: 'click', data: data[0], listener: event });
                })
                .on('mouseover', function(event, d) {
                    //console.log(this.getAttribute('id'));
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();

                    DrawSeatMapsService.listenEventsSeats.next({ event: 'mouseover', data: data[0], listener: event });
                })
                .on('mouseout', function(event, d) {
                    const a = d3.select('#' + this.getAttribute('id'));
                    const data = a.data();
                    DrawSeatMapsService.listenEventsSeats.next({ event: 'mouseout', data: data[0], listener: event });
                });
        }
    }

    static removeListenEvent(svgSeats) {
        const _platform = InjectorInstance.get<Platform>(Platform);
        const isMobil = _platform.ANDROID || _platform.IOS;
        if (isMobil) {
            svgSeats
                .on('touchstart', null)
                .on('touchmove', null)
                .on('touchcancel', null);

        } else {
            svgSeats
                .on('click', null)
                .on('mouseover', null)
                .on('mouseout', null);
        }
    }

    static inside(parentElement, childElement) {
        const p = parentElement.getBoundingClientRect();
        const c = childElement.getBoundingClientRect();
        const t = DrawSeatMapsService.getPointsFormBoundingClientRect(c).filter(value => DrawSeatMapsService.rectanglePointInside(p, value[0], value[1]));
        // console.log(t)
        return t;
    }

    static getPointsFormBoundingClientRect(point): any[][] {
        return [[point.x, point.y],
            [point.x + point.width, point.y],
            [point.x, point.y + point.height],
            [point.x + point.width, point.y + point.height]];
    }

    static rectanglePointInside(po, px, py) {
        const x = parseFloat(po.x);
        const y = parseFloat(po.y);
        const w = parseFloat(po.width);
        const h = parseFloat(po.height);
        px = parseFloat(px);
        py = parseFloat(py);
        const XY = [x, y];
        const Y = [x + w, y];
        const X = [x, y + h];
        return (XY[0] <= px && px <= Y[0] && XY[1] <= py && py <= X[1]);
    }


}
