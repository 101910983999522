import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { TicketsService } from '../../../../tickets.service';
import { SendTicketAsGiftRequest } from '../../../../../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-request';
import { SendTicketAsGiftResponse } from '../../../../../../../projects/shared-lib/src/lib/models/send-ticket-as-gift-response';
import {NgIf} from '@angular/common';

@Component({
    selector: 'tw-client-client-gift-ticket-dialog',
    template: `
<div class="dialog-content">
    <div *ngIf="this.data.email; else sendTickets">
        <h3>{{ 'client.tickets.alreadySent' | translate : ({email: this.data.email}) }}</h3>
        <h3>{{ 'client.tickets.sendConfirmation' | translate }}</h3>
    </div>
    <ng-template #sendTickets>
        <h3>{{ 'client.tickets.sendTickets' | translate }}</h3>
    </ng-template>
    <form [formGroup]="giftForm" (ngSubmit)="sendGift()">
        <input
            type="email"
            formControlName="email"
            [placeholder]="placeholder"
            class="form-control"
        >
        <div style="color:red;" *ngIf="email.invalid && (email.dirty || email.touched)" class="error-message">
            <div *ngIf="email.errors?.required"><b>{{ 'client.tickets.error.emailRequired' | translate }}</b></div>
            <div *ngIf="email.errors?.email"><b>{{ 'client.tickets.error.invalidEmailFormat' | translate }}</b></div>
        </div>
        <div class="button-group">
            <button type="submit" class="btn btn-primary" [disabled]="giftForm.invalid">{{ 'client.tickets.ok' | translate }}</button>
            <button type="button" class="btn btn-secondary" (click)="closeDialog()">{{ 'client.tickets.cancel' | translate }}</button>
        </div>
    </form>
</div>
    `,
    standalone: true,
    imports: [
        FormsModule,
        TranslateModule,
        NgIf,
        ReactiveFormsModule
    ],
    styleUrls: ['./gift-ticket-dialog.component.css']
})
export class GiftTicketDialogComponent implements OnInit {
    giftForm: FormGroup;
    sendTicketAsGiftResponse: SendTicketAsGiftResponse;
    placeholder: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<GiftTicketDialogComponent>,
        private fb: FormBuilder,
        private ticketService: TicketsService,
        private translate: TranslateService,
    ) {}

    ngOnInit(): void {
        this.giftForm = this.fb.group({
            email: [this.data.email || '', [Validators.required, Validators.email]]
        });

        this.translate.get('client.tickets.recipientEmailPlaceholder').subscribe((placeholder: string) => {
            this.placeholder = this.data.email || placeholder;
        });
    }

    get email() {
        return this.giftForm.get('email');
    }

    closeDialog() {
        this.dialogRef.close();
    }

    sendGift() {
        if (this.giftForm.invalid) {
            return;
        }

        const request: SendTicketAsGiftRequest = {
            ticketId: this.data.ticketId,
            email: this.email.value
        };

        this.ticketService.sendTicketAsGift(request)
            .subscribe({
                next: (response: SendTicketAsGiftResponse) => {
                    this.sendTicketAsGiftResponse = response;
                    console.log('Sent ticket as gift successful');
                    console.log('Response saved in variable:', this.sendTicketAsGiftResponse);
                    this.dialogRef.close(this.sendTicketAsGiftResponse);
                    this.reloadPage()
                },
                error: (e) => {
                    console.error('Failed to send ticket as gift', e);
                    this.dialogRef.close();
                },
                complete: () => {
                    console.info('Ticket sent as gift');
                }
            });
    }

    reloadPage() {
        setTimeout(() => {
            location.reload();
        }, 5000);
    }
}
