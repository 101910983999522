import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as d3Zoom from 'd3-zoom';
import { ZoomBehavior, ZoomTransform } from 'd3-zoom';
import { ControlZoomModule } from './control-zoom.module';

export interface ZoomInterface {
    action: string;
    zoomIdentity: ZoomTransform;
}

@Injectable({
    providedIn: 'root'
})
export class ZoomService {
    static watchZoom = new BehaviorSubject(<ZoomInterface>{ zoomIdentity: <ZoomTransform>d3Zoom.zoomIdentity, action: 'm' });
    static active = true;
    static zoomCurrent: ZoomTransform = d3Zoom.zoomIdentity;
    static instance: ZoomBehavior<any, any>;
    static scaleInit ;
    static bodySvg;

    constructor() {
    }
}
