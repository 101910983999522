import {Component, Inject, Input, OnInit} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HelpsService } from '../../services/helps.service';
import { ActivatedRoute } from '@angular/router';
import {MAT_DATE_LOCALE} from '@angular/material/core';

@Component({
    selector: 'tw-langs',
    templateUrl: './langs.component.html',
    styleUrls: ['./langs.component.css']
})
export class LangsComponent implements OnInit {
    @Input() languageSupported: string[] = [];
    @Input() appType: string = 'sale';
    selectedValue: string;


    constructor(public translate: TranslateService,
                public helpsService: HelpsService,
                private activatedRoute: ActivatedRoute,
                @Inject(MAT_DATE_LOCALE) private _locale: string) {
        if (!this.languageSupported) {
            this.languageSupported = [];
            this.languageSupported.push('en');
            this.languageSupported.push('fr');
        }
        this.selectedValue = this.translate.currentLang;
        //console.log(this.translate.currentLang)
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {

            this.selectedValue = event.lang;
        });
    }

    ngOnInit() {
        let lang = this.activatedRoute.snapshot.paramMap.get('lang');
        if (!lang) {
            lang = 'en';
        }
        this.selectedValue = lang;

        //console.log(this.translate.currentLang)
    }

    selectChange(val) {
        this.translate.use(val);
        if (this.appType === 'sale') {
            this.helpsService.selectChange(val);
        }

    }

}
